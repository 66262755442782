import { connect } from 'react-redux'
import ScreensCollectionComponent from "../components/ScreensCollectionComponent"
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display }) => ({
  cart: display.cart,
  managerDisplayFilter: display.managerDisplayFilter,
  managerDisplaySelector: display.managerDisplaySelector,
  disabledManagerDisplayFilter: display.disabledManagerDisplayFilter,
})

const mapStateToDispatch = (dispatch) => ({
  disableManagerDisplayFilter: (disabledManagerDisplayFilter) => dispatch(DisplayActions.disableManagerDisplayFilter(disabledManagerDisplayFilter)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreensCollectionComponent)