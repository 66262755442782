import { connect } from "react-redux";
import TopbarComponent from "../components/TopbarComponent";
import UserAccountActions from "../redux/UserAccountRedux";
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  shouldPromptLogin: userAccount.shouldPromptLogin,
  redirectTarget: userAccount.redirectTarget,
  user: userAccount.user,
  token: userAccount.token,
  require2FA: userAccount.require2FA,
  creatingCompany: userAccount.creatingCompany,
  errors: userAccount.errors,
  fetching: userAccount.fetching,
  requestingPassword: userAccount.requestingPassword,
  requestingResendVerification: userAccount.requestingResendVerification,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  currency: userAccount.currency,
  billingCountry: userAccount.billingCountry,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  currentCampaign: display.currentCampaign,
  userAccountCreatedSuccessfully: userAccount.userAccountCreatedSuccessfully
});

const mapStateToDispatch = dispatch => ({
  clearCampaignData: id => dispatch(DisplayActions.clearCampaignData(id)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  editCampaignInactive: (campaign) => dispatch(DisplayActions.editCampaignInactive(campaign)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  login: (email, password, code2FA) => dispatch(UserAccountActions.userLoginRequest(email, password, code2FA)),
  setUserLoginRequire2fa: (require2FA) => dispatch(UserAccountActions.userLoginRequire2fa(require2FA)),
  register: user => dispatch(UserAccountActions.userRegisterRequest(user)),
  registerCompany: company => dispatch(UserAccountActions.companyRegisterRequest(company)),
  userForgotPasswordRequest: email => dispatch(UserAccountActions.userForgotPasswordRequest(email)),
  resendVerificationEmailRequest: email => dispatch(UserAccountActions.resendVerificationEmailRequest(email)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  getClients: (userId) => dispatch(DisplayActions.getClientsRequest(userId)),
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
  resetUserAccountCreatedSuccessfully: ()=> dispatch(UserAccountActions.resetUserAccountCreatedSuccessfully()),
  cleanErrors: ()=> dispatch(UserAccountActions.cleanErrors()),
  history: (path) => dispatch(DisplayActions.history(path)),
  setBilling: (currency, country, codeCountry)=> dispatch(UserAccountActions.setBilling(currency, country, codeCountry)),
  setIsSuperUser: (isSuperUser)=> dispatch(UserAccountActions.setIsSuperUser(isSuperUser))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(TopbarComponent);
