import React, { useState, useEffect } from 'react';
import { Popover, Progress } from 'antd';
import Colors from "../themes/Colors";
import { useTranslation } from 'react-i18next';

const calculateShows = (reportSingleData, displayId, contentId) => {
  const foundElement = reportSingleData && reportSingleData.report.find(element => element.display === displayId && element.content === contentId);
  return foundElement ? foundElement.shows : 0;
};

const ImpressionsDetailsPopover = ({
    reportSingleData,
    customContents,
    display,
    relation,
    editCampaignActive,
    currentCampaign }) => {

  const [consumed, setConsumed] = useState(0);
  const [toConsumed, setToConsumed] = useState(0);
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [totalSubsidizePromisedShows, setTotalSubsidizePromisedShows] = useState(0);
  const [totalPromisedShows, setTotalPromisedShows] = useState(0);

  const displayId = display.id;
  const contentId = relation.content.id;
  const {t}  = useTranslation();

  const defineInitialValues = () => {
    let totalSubsidizedPromisedShows = 0;
    let totalPromisedShows = 0;
    let displayId = display.id

    if (editCampaignActive && currentCampaign.payment_status === 'approved') {
      customContents.forEach(customContent => {
        if (customContent.display_id === displayId) {
          totalPromisedShows += customContent.promised_shows - (customContent.promised_shows_subsidized || 0);
          totalSubsidizedPromisedShows += customContent.promised_shows_subsidized || 0;
        }
      });
    } else {
      customContents.forEach(customContent => {
          if (customContent.displayId === displayId) {
            totalPromisedShows += customContent.promised_shows;
            totalSubsidizedPromisedShows += customContent.promised_shows_subsidized || 0;
          }
      });
    }
    setTotalSubsidizePromisedShows(totalSubsidizedPromisedShows);
    setTotalPromisedShows(totalPromisedShows);
  };

  useEffect(() => {
    defineInitialValues();
    const shows = calculateShows(reportSingleData, displayId, contentId);
    setConsumed(shows);
    setToConsumed((relation.promised_shows + relation.promised_shows_subsidized) - shows);
    setTotalMaterial(shows + (relation.promised_shows - shows));
  }, [reportSingleData, displayId, contentId, relation]);

  const progressPercent = Math.round(((relation.promised_shows + relation.promised_shows_subsidized) * 100 / (totalPromisedShows + totalSubsidizePromisedShows) * 100) / 100);
  const successPercent = Math.round((consumed * 100 / (totalPromisedShows + totalSubsidizePromisedShows) * 100) / 100);

  return (
    <Popover
      placement="left"
      content={
        <div>
          {t('Consumed')}{" "}
          <span style={{ color: Colors.primary }}>
            {consumed}
          </span><br />
          {t('To consumed')}{" "}
          <span style={{ color: Colors.primary }}>
            {toConsumed}
          </span><br />
          {t('Total material')}{" "}
          <span style={{ color: Colors.primary }}>
            {totalMaterial}
          </span><br />
          {totalSubsidizePromisedShows > 0 ? (
            <>
              {t('Total material subsidized')}{" "}
              <span style={{ color: Colors.primary }}>
                {relation.promised_shows_subsidized}
              </span><br />
              {t('Total subsidize')}{" "}
              <span style={{ color: Colors.primary }}>
                {totalSubsidizePromisedShows}
              </span><br />
            </>
          ) : null}
          {t('Total display')}{" "}
          <span style={{ color: Colors.primary }}>
            {totalPromisedShows}
          </span>
        </div>
      }
      title={t('Impressions details')}
    >
      <Progress
        percent={progressPercent}
        successPercent={successPercent}
      />
    </Popover>
  );
};

export default ImpressionsDetailsPopover;