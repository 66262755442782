import styled from "styled-components";
import Colors from "../../themes/Colors";

const ModalCampaignManagerHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;

  h2 {
    font-size: 28px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 !important;
  }

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 0px !important;
    background: white;
    min-height: 657px;

    .ant-collapse-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 0px !important;
    
      .ant-collapse-header {
        color: ${Colors.primary} !important;
        background: white;
        padding: 0px !important;
        border: 0px !important;
        font-size: 24px;
        font-weight: 500;
        line-height: 22px !important;
        text-transform: capitalize;
        margin: 3px;
        
        .ant-collapse-arrow{
          left: unset !important;
          right: 0;
          svg {
            width: 20px;
            height: 28px;
          }
        }
      }

      .ant-collapse-content {
        border-top: 0px;

        .ant-collapse-content-box{
          padding: 0px !important;
        }
      }
    }
  }

  .slider-container {
    margin-left: 40px;
    margin-right: 40px;
  }

`;

export default ModalCampaignManagerHolder;