import { Icon, Row, Col, Tooltip, Button, Modal } from "antd";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import CartAudienceHolder from "./Styles/cartAudienceStyle";
import { SeparateNumberFormat } from "../services/utils";
import AudienceSummaryComponent from "./AudienceSummaryComponent";
import screenIcon from "../images/screen-icon-white.svg";
import { AimSvg } from "../images/customIcons/AimSvg";
import { getTotalPice, getCPM } from "../services/display";
import NumberFormat from "./NumberFormat";
import { debounce } from "lodash";

const AimIcon = (props) => <Icon component={AimSvg} {...props} />;
class CartAudience extends Component {

  constructor(props) {
    super(props);
    this.debouncedGetAudience = debounce(this.props.getAudience, 1000);
  }

  state = {
    audienceModaVisible: false,
  };

  getAudience(props, displaysCart) {
    const { date_from, date_to, customContents, programmatic, view, dsp} = props;
    let customContents_aux = customContents;
    if(programmatic === 'traditional'){
      customContents_aux = customContents.map(content => ({ ...content, promised_shows: null}));
    }

    const bodyAudienceData = {
      "_method": "GET",
      "start_date": date_from,
      "end_date": date_to,
      "content_displays": customContents_aux && view === 'cartAudienceVideoUpload' ? customContents_aux : null ,
      "displays": !customContents_aux || view !== 'cartAudienceVideoUpload' ? displaysCart : null,
      "type": programmatic,
      "external_dsp_id": dsp && programmatic == "programmatic" ? props.dsp.id : null,
    }
    this.debouncedGetAudience(bodyAudienceData);
  }

  componentWillMount() {
    const { customContents, view, cart } = this.props;

    if (!cart || cart.length === 0) return this.props.cleanAudience();

    if (cart && cart.length > 0 && view !== 'cartAudienceVideoUpload') {
      this.getAudience(this.props, this.parseDisplays(this.props));
    }

    if (customContents && customContents.length > 0 && view === 'cartAudienceVideoUpload')
      this.getAudience(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.audience).length !== 0 && (!nextProps.cart || nextProps.cart.length === 0))
      return this.props.cleanAudience();

    if (this.audienceIsUpdated(nextProps))
      this.getAudience(nextProps, this.parseDisplays(nextProps));

    if(this.props.customContents !== nextProps.customContents){
      if(this.findDifferences(this.props.customContents, nextProps.customContents)){
        this.getAudience(nextProps);
      }
    }
  }

  findDifferences = (list1, list2) => {
    let displayIds1 = list1.map(item => item.displayId);
    let displayIds2 = list2.map(item => item.displayId);
    const haveDifferentDisplayIds = !displayIds1.every(id => displayIds2.includes(id)) ||
                                    !displayIds2.every(id => displayIds1.includes(id));
    return haveDifferentDisplayIds;
  }

  audienceIsUpdated = (nextProps) => {
    const { cart, date_from, date_to, amountBudget, view } = this.props;

    //cartAudienceVideoUpload view changes are impacted in the ScreenTypePreviewComponent
    // if (view === 'cartAudienceVideoUpload') return false;

    if (nextProps.amountBudget !== amountBudget)
      return true;

    if (nextProps.date_from !== date_from || nextProps.date_to !== date_to)
      return true;

    if ((nextProps.cart && cart) && (nextProps.cart.length !== cart.length))
      return true;
    return false;
  }

  parseDisplays = (props) => {
    return props.cart.map(display => ({
      id: display.id,
      promised_shows: this.calculatePromisedShow(display, props)
    }));
  }

  calculatePromisedShow = (display, props) => {
    const { cart, amountBudget, programmatic } = props;
    if (programmatic !== 'programmatic') return null;
    return Math.trunc((parseFloat(amountBudget) / cart.length) / (display.smart_campaign_cpm / 1000));
  }

  handleAudienceModal = () => {
    this.setState(prevState => ({
      audienceModaVisible: !prevState.audienceModaVisible,
    }));
  }

  countScreensWithoutRepeating(listRelations){
    let list = []
    listRelations.forEach(display => {
      if(!list.includes(display.id)){
        list.push(display.id)
      }
    })
    return list.length
  }

  render() {
    const { t,
            cart,
            date_from,
            date_to,
            audience,
            loadingAudience,
            view ,
            programmatic,
            user,
            currentCampaign,
            editCampaignActive,
            amountBudget,
            realAmount,
            currencyExchangeRate,
            dsp} = this.props;

    const { audienceModaVisible } = this.state;

    let amountBudgetProgrammatic = amountBudget
    if (view === 'cartAudienceVideoUpload'){
      amountBudgetProgrammatic = Math.round(realAmount * 100) / 100
    }

    // Cost per thousand impressions
    let campaingPrice = programmatic === "programmatic" ? amountBudgetProgrammatic : getTotalPice(cart, date_from, date_to)
    let CPM =  getCPM(campaingPrice, audience.total_impacts)

    return (
      (audience.displays_with_audience ?
        <CartAudienceHolder>
          <div className={(audience.displays_with_audience.length > 0 && audience.total_audience !== 0) ? (view ? view : "cartAudience") : "cartAudienceHidden"} style={{marginTop: (currencyExchangeRate && currencyExchangeRate.currency != "USD" && !!dsp && dsp.id) ? "35px" : "0"}}>
            <Row style={{marginLeft:"15px"}}>
              <Col className={"col-card col-card-margin text-title"}>{t('Estimated audience')}{" "}<br /></Col>
              <Col className={"col-card col-card-margin text-data"} style={{ margin: "0px", fontSize: "12px" }}>
                {loadingAudience && <Icon type="loading" />}
                {!loadingAudience &&
                  <React.Fragment>
                    <div className="content-card">
                      <Icon type="usergroup-add" theme="outlined" style={{ marginRight: "7px", fontSize: "large"}} />
                      <span className="cardAudienceInfo">{SeparateNumberFormat(audience.total_audience)}</span>
                      <span className="cardAudienceTypeInfo">{t('Audience impacts')}</span>
                    </div>
                    <div className="impacts-container content-card">
                      <AimIcon className="aimIcon"  style={{marginRight: "7px"}} />
                        <span className="cardAudienceInfo" >{SeparateNumberFormat(audience.total_impacts)}</span>
                        <span className="cardAudienceTypeInfo"> {t('impacts')}
                        {programmatic === 'traditional' &&
                          <Tooltip
                            placement="left"
                            title= {t('Value based on screen occupancy statistics, so it can vary daily.')}>
                            <Icon className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                          </Tooltip>
                        }
                        </span>
                    </div>
                    <div className="content-card">
                      {(this.props.cart.length > 0 && programmatic != 'traditional' && CPM > 0) &&
                      <>
                        <span className="cardAudienceInfo" style={{marginLeft: "25px"}} >
                          <NumberFormat
                            value={CPM}
                            currency={this.props.cart[0].price_currency}
                          />
                        </span>
                        <span className="cardAudienceTypeInfo">{t('CPM')}</span>
                        <Tooltip
                          placement="left"
                          title= {t('(CPM) cost per thousand person')}>
                          <Icon style={{paddingTop: "7px"}} className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                        </Tooltip>
                      </>
                      }
                    </div>
                  </React.Fragment>
                }
              </Col>
              <div className="line-divider ant-divider ant-divider-horizontal"></div>
              <Col className={"col-card"} style={{ fontSize: "large", margin: "5px 11px 5px 11px" }}>
                <Row type="flex" justify="space-around">
                  <Tooltip
                    placement="left"
                    title={`${t('Screens measuring audience')}.`}>
                    {this.countScreensWithoutRepeating(audience.displays_with_audience)} <Icon type="audit" theme="outlined" />
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title={`${t('Total screens added in the campaign')}.`}>
                    {cart.length}
                    <img src={screenIcon} className="iconAudience" style={{height: "18px", width: "18px"}} />
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title={`${t('See audience details')}`}>
                    <Icon type="eye"
                      theme="outlined"
                      style={{ marginTop: "3px" }}
                      onClick={this.handleAudienceModal}
                    />
                  </Tooltip>
                </Row>
              </Col>
            </Row>
          </div>
          <Modal
            title={t("Audience summary")}
            visible={audienceModaVisible}
            onCancel={this.handleAudienceModal}
            footer={[
              <Button key="back" onClick={this.handleAudienceModal}>
                OK
              </Button>,
            ]}
            width={1000}
            style={{ top: "10px" }}
          >
            <AudienceSummaryComponent
              t={t}
              start_date={date_from}
              end_date={date_to}
              displays={cart}
              resetAudience={audienceModaVisible}
              user={user}
              campaign={ editCampaignActive ? currentCampaign : null}
              {...this.props}
            />
          </Modal>
        </CartAudienceHolder>
        : null
      )
    );
  }
}

export default withTranslation()(CartAudience);