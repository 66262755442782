import styled from "styled-components";

const GenerateSkeletonHolder = styled.div`
  .general-skeleton {
    flex-wrap: nowrap;
    gap: 20px;
    margin: 0px !important;
  }
  .skeletonCard li{
    height: 100%;
  }
  .skeletonCard .ant-skeleton-title {
    display: none;
  }
  .skeletonCard .ant-skeleton-paragraph {
    margin: 0px;
  }
`;

export default GenerateSkeletonHolder;