import { takeLatest, all } from 'redux-saga/effects'
import api from '../services/apiInstance'

/* ------------- Types ------------- */

import { UserAccountTypes } from '../redux/UserAccountRedux'
import { DisplayTypes } from '../redux/DisplayRedux'


/* ------------- Sagas ------------- */

import {
  userLoginRequest,
  userLoginFromCmsRequest,
  userRegisterRequest,
  companyRegisterRequest,
  userForgotPasswordRequest,
  resendVerificationEmailRequest,
  userResetPasswordRequest,
  userEmailUpdateRequest,
  userPasswordUpdateRequest,
  userLogout,
  getCountriesRequest,
  getLocationRequest,
} from "./UserAccountSagas";
import {
  getDisplaysRequest,
  getDisplaysCartRequest,
  getDisplayInfoRequest,
  getDisplaysInfoRequest,
  getExcelDisplaysRequest,
  uploadFileRequest,
  createCampaignRequest,
  editCampaignRequest,
  getCampaignsRequest,
  archiveCampaignRequest,
  getCampaignRequest,
  deleteCampaignRequest,
  createReplicateCampaignRequest,
  requestSpecialCpmRequest,
  requestSpecialPriceRequest,
  getClientsRequest,
  getBrandsRequest,
  getExternalDspsRequest,
  getQuotesRequest,
  getQuoteRequest,
  deleteQuoteRequest,
  createQuoteRequest,
  editQuoteRequest,
  setArchivedQuoteRequest,
  updateDefaultContentRequest,
  updateCustomContentRequest,
  updateContentCampaignRequest,
  createPaymentRequest,
  createPurchaseOrdersRequest,
  getCampaignPaymentsRequest,
  getPaymentRequest,
  deleteCampaignPaymentRequest,
  getMpInitPointRequest,
  getReportRequest,
  getProposalRequest,
  getSingleReportRequest,
  getLastBillingInfoRequest,
  createCouponRequest,
  deleteCouponRequest,
  getAudienceRequest,
  getAudienceDatsWhyRequest,
  getCurrencyExchangeRateRequest,
  getConversionRatesRequest,
  getCategoriesRequest,
  getContentsByCompanyRequest,
} from "./DisplaySagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

// const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(UserAccountTypes.USER_LOGIN_REQUEST, userLoginRequest, api),
    takeLatest(UserAccountTypes.USER_LOGIN_FROM_CMS_REQUEST, userLoginFromCmsRequest, api),
    takeLatest(UserAccountTypes.USER_REGISTER_REQUEST, userRegisterRequest, api),
    takeLatest(UserAccountTypes.USER_RESET_PASSWORD_REQUEST, userResetPasswordRequest, api),
    takeLatest(UserAccountTypes.USER_FORGOT_PASSWORD_REQUEST, userForgotPasswordRequest, api),
    takeLatest(UserAccountTypes.RESEND_VERIFICATION_EMAIL_REQUEST, resendVerificationEmailRequest, api),
    takeLatest(UserAccountTypes.USER_EMAIL_UPDATE_REQUEST, userEmailUpdateRequest, api),
    takeLatest(UserAccountTypes.USER_PASSWORD_UPDATE_REQUEST, userPasswordUpdateRequest, api),
    takeLatest(UserAccountTypes.USER_LOGOUT, userLogout, api),

    takeLatest(UserAccountTypes.GET_COUNTRIES_REQUEST, getCountriesRequest, api),

    takeLatest(UserAccountTypes.GET_LOCATION_REQUEST, getLocationRequest, api),

    takeLatest(UserAccountTypes.COMPANY_REGISTER_REQUEST, companyRegisterRequest, api),

    takeLatest(DisplayTypes.GET_DISPLAYS_REQUEST, getDisplaysRequest, api),
    takeLatest(DisplayTypes.GET_DISPLAYS_CART_REQUEST, getDisplaysCartRequest, api),
    takeLatest(DisplayTypes.GET_DISPLAY_INFO_REQUEST, getDisplayInfoRequest, api),
    takeLatest(DisplayTypes.GET_DISPLAYS_INFO_REQUEST, getDisplaysInfoRequest, api),
    takeLatest(DisplayTypes.GET_EXCEL_DISPLAYS_REQUEST, getExcelDisplaysRequest, api),
    takeLatest(DisplayTypes.UPDATE_DEFAULT_CONTENT_REQUEST, updateDefaultContentRequest, api),
    takeLatest(DisplayTypes.UPDATE_CUSTOM_CONTENT_REQUEST, updateCustomContentRequest, api),
    takeLatest(DisplayTypes.UPDATE_CONTENT_CAMPAIGN_REQUEST, updateContentCampaignRequest, api),
    takeLatest(DisplayTypes.CREATE_CAMPAIGN_REQUEST, createCampaignRequest, api),
    takeLatest(DisplayTypes.EDIT_CAMPAIGN_REQUEST, editCampaignRequest, api),
    takeLatest(DisplayTypes.ARCHIVE_CAMPAIGN_REQUEST, archiveCampaignRequest, api),
    takeLatest(DisplayTypes.GET_CAMPAIGNS_REQUEST, getCampaignsRequest, api),
    takeLatest(DisplayTypes.GET_CAMPAIGN_REQUEST, getCampaignRequest, api),
    takeLatest(DisplayTypes.DELETE_CAMPAIGN_REQUEST, deleteCampaignRequest, api),
    takeLatest(DisplayTypes.CREATE_REPLICATE_CAMPAIGN_REQUEST, createReplicateCampaignRequest, api),
    takeLatest(DisplayTypes.REQUEST_SPECIAL_CPM_REQUEST, requestSpecialCpmRequest, api),
    takeLatest(DisplayTypes.REQUEST_SPECIAL_PRICE_REQUEST, requestSpecialPriceRequest, api),
    takeLatest(DisplayTypes.GET_CLIENTS_REQUEST, getClientsRequest, api),
    takeLatest(DisplayTypes.GET_BRANDS_REQUEST, getBrandsRequest, api),
    takeLatest(DisplayTypes.GET_EXTERNAL_DSPS_REQUEST, getExternalDspsRequest, api),
    takeLatest(DisplayTypes.GET_QUOTES_REQUEST, getQuotesRequest, api),
    takeLatest(DisplayTypes.GET_QUOTE_REQUEST, getQuoteRequest, api),
    takeLatest(DisplayTypes.DELETE_QUOTE_REQUEST, deleteQuoteRequest, api),
    takeLatest(DisplayTypes.CREATE_QUOTE_REQUEST, createQuoteRequest, api),
    takeLatest(DisplayTypes.EDIT_QUOTE_REQUEST, editQuoteRequest, api),
    takeLatest(DisplayTypes.SET_ARCHIVED_QUOTE_REQUEST, setArchivedQuoteRequest, api),
    takeLatest(DisplayTypes.UPLOAD_FILE_REQUEST, uploadFileRequest, api),

    // takeLatest(DisplayTypes.GET_MERCADO_PAGO_PAYMENT_METHODS_REQUEST, getMercadoPagoPaymentMethodsRequest, api),
    takeLatest(DisplayTypes.CREATE_PAYMENT_REQUEST, createPaymentRequest, api),
    takeLatest(DisplayTypes.CREATE_PURCHASE_ORDERS_REQUEST, createPurchaseOrdersRequest, api),
    takeLatest(DisplayTypes.GET_CAMPAIGN_PAYMENTS_REQUEST, getCampaignPaymentsRequest, api),
    takeLatest(DisplayTypes.DELETE_PAYMENT_REQUEST, deleteCampaignPaymentRequest, api),
    takeLatest(DisplayTypes.GET_PAYMENT_REQUEST, getPaymentRequest, api),
    takeLatest(DisplayTypes.GET_MP_INIT_POINT_REQUEST, getMpInitPointRequest, api),
    takeLatest(DisplayTypes.GET_REPORT_REQUEST, getReportRequest, api),
    takeLatest(DisplayTypes.GET_PROPOSAL_REQUEST, getProposalRequest, api),
    takeLatest(DisplayTypes.GET_SINGLE_REPORT_REQUEST, getSingleReportRequest, api),
    takeLatest(DisplayTypes.GET_LAST_BILLING_INFO_REQUEST, getLastBillingInfoRequest, api),
    takeLatest(DisplayTypes.CREATE_COUPON_REQUEST, createCouponRequest, api),
    takeLatest(DisplayTypes.DELETE_COUPON_REQUEST, deleteCouponRequest, api),

    takeLatest(DisplayTypes.GET_AUDIENCE_REQUEST, getAudienceRequest, api),
    takeLatest(DisplayTypes.GET_AUDIENCE_DATS_WHY_REQUEST, getAudienceDatsWhyRequest, api),
    takeLatest(DisplayTypes.GET_CURRENCY_EXCHANGE_RATE_REQUEST, getCurrencyExchangeRateRequest, api),
    takeLatest(DisplayTypes.GET_CONVERSION_RATES_REQUEST, getConversionRatesRequest, api),
    takeLatest(DisplayTypes.GET_CATEGORIES_REQUEST, getCategoriesRequest, api),

    takeLatest(DisplayTypes.GET_CONTENTS_BY_COMPANY_REQUEST, getContentsByCompanyRequest, api),
  ])
}
