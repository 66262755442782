import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import GenerateSkeletonHolder from './Styles/generateSkeletonStyle';

const GenerateSkeletonComponent = ({ count, height = 220, width = 120 }) => {
    return (
        <GenerateSkeletonHolder>
            <Row className="general-skeleton" type="flex" gutter={16} >
                {Array.from({ length: count }).map((_, index) => (
                    <Col key={index} span={24 / count} style={{ padding: 0 }}>
                        <Skeleton
                            title={{ width: 0 }}
                            className='skeletonCard'
                            active
                            paragraph={{ rows: 1, width, style: { height } }}
                        />
                    </Col>
                ))}
            </Row>
        </GenerateSkeletonHolder>
    );
};

export default GenerateSkeletonComponent;