import React, { Component } from "react";
import PropTypes from "prop-types";
import VideoUploadHolder from "./Styles/videoUploadComponentStyle";
import CheckoutStepsContainer from "../containers/CheckoutStepsContainer";
import ScreenTypePreviewContainer from "../containers/ScreenTypePreviewContainer";
import { Button, Input, Row, Col, Upload, Icon, Form, Card, message, Modal, DatePicker, TimePicker, Checkbox, notification, Tooltip, Spin, Switch, Select} from "antd";
import { withRouter } from "react-router";
import { getAspectRatio, getSimilarAspectRatio } from "../services/display";
import storage from "redux-persist/lib/storage";
import { remove, debounce} from "lodash";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import canvaLogo from "../images/canva-icon.png";
import NumberFormat from "./NumberFormat";
import CartAudienceContainer from "../containers/CartAudienceContainer";
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole, validateCharLimit } from "../services/utils"
import Colors from "../themes/Colors";
import VerifiedAgencyInfoModal from './VerifiedAgencyInfoModal';
import CheckCompanyForUserComponent from './CheckCompanyForUserComponent';

const { Option } = Select;
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const Dragger = Upload.Dragger;

class VideoUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.debouncedGetAudience = debounce(this.props.getAudience, 500)
  }
  state = {
    errors: {},
    excessTraditionalVideoLength: false,
    excessProgrammaticVideoLength: false,
    visibleClearModal: false,
    generalRuleActive: false,
    rules: [
      {"conditions":[]}
    ],
    arrayTime: {
      "type":"hour",
      "from": "00:00",
      "to": "23:59",
    },
    arrayDays: {
      "type":"day",
      "value":[]
    },
    canva: {},
    uploadingFile: false,
    canvaModalVisible: false,
    width: 300,
    height: 300,
    totalShows: 0,
    totalPromisedShows: 0,
    subsidizeTotalPromisedShows :0,
    checkNullRules: false,
    subsidized_all: false,
    inputCharLimit: {
      value: 0,
    },
    areaCharLimit: {
      value: 0,
    },
    arrContentChanged: [],
    isVerifiedAgencyModalVisible: false,
    isAgencyOwner: false,
    permissionsTransferSpots: false,
    // Registry of the credit available on transferred impressions when closing the modal
    creditAvailableOnTransferredImpressions: [],
    transferModalVisible: null,
  };

  isSubmitOk() {
    const errors = {};
    // Check that rules isn't null for day
    this.checkNullRules = false
    this.props.customContents.map(content => {
      if(content.rules){
        content.rules.map(rule =>{
          if(rule.conditions.find(condition => condition.type === 'day').value.length < 1){
            this.checkNullRules = true
            return;
          }
        })
      }
    })
    if(this.checkNullRules){
      return false
    }
    if (!this.props.campaignName) {
      errors["campaignName"] = this.props.t('Can not be blank');
    }
    if (!this.props.defaultContent.ready) {
      errors["defaultContent"] = this.props.t('the file is not ready yet');
    }
    if (!this.props.defaultContent.id) {
      errors["defaultContent"] = this.props.t('upload a file for the campaign');
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors
      });

      return false;
    }

    return true;
  }

  handleNameChange(e) {
    this.props.setCampaignName(e.target.value);

    this.setState({
      errors: {},
      inputCharLimit: {
        ...validateCharLimit(e.target.value.length, 40, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });

    validateCharLimit()
  }

  handleDescriptionChange(e) {
    this.props.setCampaignDescription(e.target.value);

    this.setState({
      areaCharLimit: {
        ...validateCharLimit(e.target.value.length, 200, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });

    validateCharLimit();
  }

  handleIsCampaignsTestToggle = (isCampaignTest) => {
    this.props.setIsCampaignTest(isCampaignTest);
  }

  handleIsCampaignsPausedToggle = (isCampaignPaused) => {
    this.props.setIsCampaignPaused(isCampaignPaused);
  }

  getIsCampaignsPaused = () => {
    const arrPausedContent = this.props.customContents.filter(content => content.active === 0)
    if (arrPausedContent.length === this.props.customContents.length) {
      return true
    }
    return false;
  }

  showClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: true,
    });
  }

  hideClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: false,
    });
  };

  clearCampaignData = () => {
    this.setState({
      visibleClearModal: false,
    });
    this.props.clearCampaignData();
  };

  addGeneralRules = () => {
    // this.setState({
    //   generalRuleActive: !this.state.generalRuleActive,
    //   rules: [{"conditions":[]}]
    // });
    this.state.generalRuleActive = !this.state.generalRuleActive;
    this.state.rules = [{"conditions":[]}];
    this.props.setDefaultRules([]);
  };

  changeDate = (date, dateString) => {
    const arrayDate = {"type":"date","from_date":moment(dateString[0], "DD/MM/YYYY").format("YYYY-MM-DD"),"to_date":moment(dateString[1], "DD/MM/YYYY").format("YYYY-MM-DD")}
    if (this.state.rules[0].conditions.find(condition => condition.type === 'date')) {
      remove(this.state.rules[0].conditions, function(condition) {
        return condition.type === 'date';
      });
      this.state.rules[0].conditions.push(arrayDate)
    } else {
      this.state.rules[0].conditions.push(arrayDate)
    }
    this.props.setDefaultRules(this.state.rules);
  };

  changeDay = (e) => {
    if (e.target.checked) {
      if (parseInt(e.target.value) === 1) {
        this.state.arrayDays.value.push(1)
      } else if (parseInt(e.target.value) === 2) {
        this.state.arrayDays.value.push(2)
      } else if (parseInt(e.target.value) === 3) {
        this.state.arrayDays.value.push(3)
      } else if (parseInt(e.target.value) === 4) {
        this.state.arrayDays.value.push(4)
      } else if (parseInt(e.target.value) === 5) {
        this.state.arrayDays.value.push(5)
      } else if (parseInt(e.target.value) === 6) {
        this.state.arrayDays.value.push(6)
      } else if (parseInt(e.target.value) === 0) {
        this.state.arrayDays.value.push(0)
      }
    } else {
      if (parseInt(e.target.value) === 1) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 1;
        });
      } else if (parseInt(e.target.value) === 2) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 2;
        });
      } else if (parseInt(e.target.value) === 3) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 3;
        });
      } else if (parseInt(e.target.value) === 4) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 4;
        });
      } else if (parseInt(e.target.value) === 5) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 5;
        });
      } else if (parseInt(e.target.value) === 6) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 6;
        });
      } else if (parseInt(e.target.value) === 0) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 0;
        });
      }
    }
    if (this.state.rules[0].conditions.find(condition => condition.type === 'day')) {
      remove(this.state.rules[0].conditions, function(condition) {
        return condition.type === 'day';
      });
      this.state.rules[0].conditions.push(this.state.arrayDays)
    } else {
      this.state.rules[0].conditions.push(this.state.arrayDays)
    }
    this.props.setDefaultRules(this.state.rules);
  };

  changeStartTime = (time, timeString) => {
    this.state.arrayTime.from = timeString;
  };

  changeEndTime = (time, timeString) => {
    this.state.arrayTime.to = timeString;
    if (this.state.rules[0].conditions.find(condition => condition.type === 'hour')) {
      remove(this.state.rules[0].conditions, function(condition) {
        return condition.type === 'hour';
      });
      this.state.rules[0].conditions.push(this.state.arrayTime)
    } else {
      this.state.rules[0].conditions.push(this.state.arrayTime)
    }
    this.props.setDefaultRules(this.state.rules);
  };

  updateByAmountBudget = () => {
    const displayIds = this.props.cart.map(d => d.id);
    this.props.updateRealAmount(this.props.amountBudget)
    for (var i = 0; i < displayIds.length; i++) {
      const customContents = this.props.customContents.filter(c =>
        c.displayId === displayIds[i]
      );
      for (var j = 0; j < customContents.length; j++) {
        if (customContents[j].displayId && customContents[j].relationId) {
          this.props.updateImpressionsDisplay(customContents[j].displayId, customContents[j].relationId, Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length / (customContents.length) ) / (this.props.cart.find(display => display.id === customContents[j].displayId).smart_campaign_cpm/1000)));
        }
      }
    }
  };

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  updateCreditAvailable = (creditAvailable) => {
    const { creditAvailableOnTransferredImpressions } = this.state;
    const indiceExistente = creditAvailableOnTransferredImpressions.findIndex(item => item.company_id === creditAvailable.company_id);

    if (indiceExistente !== -1) {
      creditAvailableOnTransferredImpressions[indiceExistente] = creditAvailable;
    } else {
      creditAvailableOnTransferredImpressions.push(creditAvailable);
    }
    this.setState({ creditAvailableOnTransferredImpressions });
  };

  showTransferModal = (relation) => {
    this.setState({
      transferModalVisible: relation,
    });
  }

  componentDidMount() {
    const { isSuperUser } = this.props;

    if(!this.props.categories || this.props.categories.length === 0){
      this.props.getCategories();
    }

    if(this.props.editCampaignActive){
      let permissionsTransferSpots = (this.props.isSuperUser &&
        this.props.programmatic === 'programmatic' &&
        this.props.editCampaignActive &&
        this.props.currentCampaign.payment_status === 'approved') ? true : false;
      this.setState({permissionsTransferSpots : permissionsTransferSpots});
    }

    if (!this.props.user) {
      this.props.history.push("/");
      this.props.setShouldPromptLogin(true, "/");
    } else if (this.props.cart.length === 0) {
      this.props.history.push("/map");
    }

    var totalShows = 0;
    if (this.props.reportSingleData && this.props.reportSingleData.report) {
      totalShows = this.props.reportSingleData.report.reduce(
        (acc, display) => {
          return acc + display.shows;
        },
        0
      );
      this.setState({ totalShows: totalShows });
    }

    var totalPromisedShows = 0;
    var subsidizeTotalPromisedShows = 0;

    this.props.currentCampaign && this.props.currentCampaign.contents_displays.forEach(function(content_display) {
      totalPromisedShows = totalPromisedShows + content_display.promised_shows
      subsidizeTotalPromisedShows = subsidizeTotalPromisedShows + content_display.promised_shows_subsidized
    });
    this.setState({ totalPromisedShows: totalPromisedShows });
    this.setState({ subsidizeTotalPromisedShows: subsidizeTotalPromisedShows });
    this.props.editCampaignTotalPromisedShows(totalPromisedShows);

    if (window.Canva && window.Canva.DesignButton) {
      window.Canva.DesignButton.initialize({
        apiKey: 'o7N0aHo3ZNyQHduYZBam5MMh',
      }).then((canva)=> {
        this.props.setCanvaInit(canva)
      });
    }

    window.scrollTo(0, 0)
    if (this.props.defaultContent.id && !this.props.defaultContent.ready) {
      this.props.updateDefaultContentRequest(this.props.defaultContent.id);
    }
    for (var i = 0; i < this.props.customContents.length; i++) {
      if( this.props.customContents[i].subsidized_price && this.props.customContents[i].subsidized_price == false ){
        this.setState({
          subsidized_all: false
        });
      }
    }
    return storage
      .getItem("authHeaders")
      .then(headers => (!headers ? this.props.history.push("/") : null));
  }

  componentDidUpdate (prevProps) {
    // This function update all relations with the new default content
    if ( prevProps.defaultContent && (
         (this.props.defaultContent.id !== prevProps.defaultContent.id) && this.props.defaultContent.ready ||
         ((this.props.defaultContent.ready !== prevProps.defaultContent.ready) && this.props.defaultContent.ready)
        )) {
      const content_display_aux = this.props.customContents.map(customContent => {
        return {
          ...customContent,
          content: this.props.defaultContent
        }
      })
      this.props.updateCustomContents(content_display_aux);
    }
  }

  createCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully created'),
      description: this.props.t('You can see it in the My campaigns section.'),
      duration: 8,
      placement
    });
  };

  editCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully edited'),
      description: this.props.t('You can review it from this section before generating a payment.'),
      duration: 8,
      placement
    });
  };

  // Define is necesary call getAudience
  changedDataOrRules = (nextProps) => {
    let { date_from, date_to, customContents, audience, displays } = this.props;
    if ( audience.displays_with_audience && audience.displays_with_audience.length === 0) return false;
    //upload file
    if (nextProps.customContents === customContents) return false;

    //newContent
    if ((customContents && nextProps.customContents) && (customContents.length !== nextProps.customContents.length)) {
      let lastContent = nextProps.customContents[nextProps.customContents.length - 1];
      if (!lastContent) return false
      if (!displays.some(display => display.id === (lastContent.displayId))) return false
      return true;
    }

    let arrContentChanged = [];
    arrContentChanged = customContents.filter((content, key) => {
      const lengthRulesCurrent = content && content.rules && content.rules.length
      const lengthRulesNext = nextProps.customContents[key] && nextProps.customContents[key].rules && nextProps.customContents[key].rules.length
      //compare changes in promised_show/impacts
      if (nextProps.customContents[key] && (content.promised_shows !== nextProps.customContents[key].promised_shows)) return true;
      //new rules
      if (lengthRulesCurrent === 0 && lengthRulesNext === 1) return true
      //compare changes in rules
      if (lengthRulesCurrent > 0 && (nextProps.customContents[key] && nextProps.customContents[key].rules) !== content.rules) {
        //change on this screen?
        if (!displays.some(display => display.id === content.displayId)) return false
        //have audience?
        if (audience.displays_with_no_audience.some(display => display.id === content.displayId)) return false
        return true
      };
      return false;
    });

    // Save relation in state that modify for then check audience
    if (arrContentChanged.length > 0) {
      this.setState({ arrContentChanged });
      return true;
    }

    if (nextProps.date_from !== date_from) return true;
    if (nextProps.date_to !== date_to) return true;
    return false;
  }

  getAudience(props) {
    let { date_from, date_to, customContents, programmatic, dsp} = props;
    let customContents_aux = customContents
    if(this.props.programmatic === 'traditional'){
      customContents_aux = customContents.map(content => ({ ...content, promised_shows: null}));
    }
    const getAudienceData = {
      "_method": "GET",
      "start_date": date_from,
      "end_date": date_to,
      "content_displays": customContents_aux,
      "type": programmatic,
      "external_dsp_id": dsp && programmatic == "programmatic" ? props.dsp.id : null,
    }

    this.debouncedGetAudience(getAudienceData);
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
      let permissionsTransferSpots = (this.props.isSuperUser &&
        this.props.programmatic === 'programmatic' &&
        this.props.editCampaignActive &&
        this.props.currentCampaign.payment_status === 'approved') ? true : false;
      this.setState({permissionsTransferSpots : permissionsTransferSpots});
    // Define if transfer spots between displays
    // if(nextProps.editCampaignActive != this.props.editCampaignActive){
    //   let permissionsTransferSpots = (nextProps.isSuperUser &&
    //     nextProps.programmatic === 'programmatic' &&
    //     nextProps.editCampaignActive &&
    //     nextProps.currentCampaign.payment_status === 'approved') ? true : false;
    //   this.setState({permissionsTransferSpots : permissionsTransferSpots});
    // }

    if (this.changedDataOrRules(nextProps) && !nextProps.loadingAudience && !prevProps.loadingAudience) this.getAudience(nextProps);

    if (this.props.creatingCampaign && !nextProps.creatingCampaign) {
      if (nextProps.errors.length === 0) {
        this.props.history.push(`/campaign/${nextProps.campaign.id}/payment`);
        this.createCampaignSuccess('topRight');
        return;
      }
      if (nextProps.errors[0] && nextProps.errors[0].contents_displays && nextProps.errors[0].contents_displays[0] === 'The contents displays field is required.') {
        message.error(this.props.t('You have not selected screens, you can go back to the map and do it from there.'));
      } else if (nextProps.errors[0] === 'Company is not verified and has more than $5000 USD in campaigns this month') {
        this.showVerifiedAgencyModal();
      }
      else {
        message.error(this.props.t('An error occurred, please try again'));
      }
    }
    if (this.props.editingCampaign && !nextProps.editingCampaign) {
      if (nextProps.errors.length === 0) {
        this.props.history.push(`/campaign/${nextProps.campaign.id}`);
        this.editCampaignSuccess('topRight');
        this.props.editCampaignInactive();
        return;
      }

      if (nextProps.errors && nextProps.errors[0].contents_displays && nextProps.errors[0].contents_displays[0] === 'The contents displays field is required.') {
        message.error(this.props.t('You have not selected screens, you can go back to the map and do it from there.'));
      } else if (nextProps.errors && nextProps.errors[0].message) {
        message.error(nextProps.errors[0].message);
      } else {
        message.error(this.props.t('An error occurred, please try again'));
      }
    }
    if (nextProps.errors && nextProps.errors.length > 0 && nextProps.errors[0] === 'Token has expired') {
      message.error(this.props.t('Token expired, please login again'), 6);
      this.props.history.push("/");
      this.props.logout();
    }
    if (this.props.loadingUploadFile && !nextProps.loadingUploadFile && this.state.uploadingFile) {
      setTimeout(() => {
        this.props.setDefaultContent(this.props.fileNew.resp.data);
        this.setState({
          uploadingFile: false,
        });
      }, 2000);
    }
    let contSubsidized = 0
    if(nextProps.customContents){
      for (var i = 0; i < nextProps.customContents.length; i++) {
        if(nextProps.customContents[i].subsidized_price == false ){
          this.setState({
            subsidized_all: false
          });
        }
        if(nextProps.customContents[i].subsidized_price == true ){
          contSubsidized++
        }
      }
      if(nextProps.customContents.length == contSubsidized && nextProps.customContents.length != 0){
        this.setState({
          subsidized_all: true
        });
      }
    }
  }

  createCanvaDesign = () => {
    this.handleCanvaClose();
    this.props.canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: this.state.width ? this.state.width : 300,
          height: this.state.height ? this.state.height : 300,
          units: 'px',
        },
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              uploadingFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 4000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('hacer algo al cerrar');
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleCanvaClose = e => {
    this.setState({
      canvaModalVisible: false
    });
  };

  showCanvaModal = () => {
    this.setState({
      canvaModalVisible: true
    });
  };

  changeSubsidizedPrice = () =>{
    this.setState({
      subsidized_all: !this.state.subsidized_all
    });
    this.props.updateSubsidizedPrice(
      null,
      !this.state.subsidized_all,
      'multiple'
    )
  }
  changeCategory = (value) => {
    this.props.setCategoriesId(value);
  }

  render() {
    const { errors, visibleClearModal, canvaModalVisible, inputCharLimit, areaCharLimit, arrContentChanged, isAgencyOwner} = this.state;
    const {t, user , currentCampaign, i18n, isSuperUser} = this.props;
    const { RangePicker } = DatePicker;
    const allDisplaysAreOwn = this.props.cart && this.props.cart.every(display => {
      const isOwnDisplay = (display.company_id ? display.company_id : display.company.id) == user.company_id;
      const displayHoldingId = display.holding_id ? display.holding_id : display.company.holding_id;
      const isHoldingDisplay = user.roles.includes('holding-member') && user.holding && user.holding.id === displayHoldingId;
      return isOwnDisplay || isHoldingDisplay;
    });
    const displaysByAspectRatio = [];
    const TooltipSubsidized = !this.props.defaultContent.id ? t('First load content for subsidize the campaign') : null
    this.state.excessTraditionalVideoLength = false;
    this.state.excessProgrammaticVideoLength = false;
    this.props.cart.forEach(display => {
      const current = displaysByAspectRatio.find(
        d => d.typeSize === getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t)
      );
      if (this.props.programmatic === 'traditional' && this.props.defaultContent.type === 'video' && Math.floor(this.props.defaultContent.length / 1000) * 1000 > display.slot_length) {
        this.state.excessTraditionalVideoLength = true;
      }
      if (this.props.programmatic === 'programmatic' && this.props.defaultContent.type === 'video' && this.props.defaultContent.length > 11000) {
        this.state.excessProgrammaticVideoLength = true
      }
      if (!current) {
        displaysByAspectRatio.push({
          aspectRatio: getAspectRatio(display.resolution_width, display.resolution_height),
          typeSize: getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t),
          displays: [display]
        });
      } else {
        current.displays.push(display);
      }
    });

    const uploadProps = {
      name: "file",
      accept: "video/*,image/*,video/mp4,video/x-m4v",
      action: `${apiUrl}/contents`,
      multiple: false,
      beforeUpload: async (file, _fileList) => {
        return new Promise((resolve, reject) => {
          // Check size
          const isLt50M = file.size / 1024 / 1024 < 50;
          if (!isLt50M) {
            message.error(this.props.t('The file cannot be larger than 50MB!'), 10);
            reject();
          }
          // Check resolution
          const fileType = file.type;
          const maxResolution = 10000;
          const _this =  this;
          // Check image resolution
          if (fileType.startsWith('image/')) {
            const img = new Image();
            img.onload = function () {
              const width = img.width;
              const height = img.height;
              if (width > maxResolution || height > maxResolution) {
                message.error(_this.props.t('The resolution can not be larger than 10000px'), 10);
                reject();
              } else {
                resolve(file);
              }
            };
            img.src = URL.createObjectURL(file);
          } else if (fileType.startsWith('video/')) {
            // Check video resolution
            const video = document.createElement('video');
            video.onloadedmetadata = function () {
              const width = video.videoWidth;
              const height = video.videoHeight;
              if (width > maxResolution || height > maxResolution) {
                message.error(_this.props.t('The resolution can not be larger than 10000px'), 10);
                reject();
              } else {
                resolve(file);
              }
            };
            video.src = URL.createObjectURL(file);
          }
        });
      },
      headers: { authorization: `Bearer ${this.props.token}` },
      onChange: async info => {
        const status = info.file.status;
        if (status !== "uploading") {
          //console.log(info.file, info.fileList);
        }
        if (status === "done") {
          this.props.setDefaultContent(info.file.response);
          message.success(`${info.file.name} ${t('successfully uploaded')}`);
          while (!this.props.defaultContent.ready) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            this.props.updateDefaultContentRequest(info.file.response.id);
          }
        } else if (status === "error") {
          message.error(`${t('Upload error')} ${info.file.name}`);
        }
      }
    };

    const { TextArea } = Input;

    return (
      <VideoUploadHolder>

        { user.permissions.includes('switch_company') ?
          <CheckCompanyForUserComponent
            user={user}
            userUpdate={this.props.userUpdate}
            history={this.props.history}
            />
        : null }

          <CheckoutStepsContainer
          isSubmitOk={() => this.isSubmitOk()}
          ready={this.props.defaultContent.ready}
        />
        <Row type="flex" justify="center" align="middle">
          <Col
            className="videoUploadContainer"
            xs={24}
            sm={24}
            md={22}
            lg={22}
            xl={18}
          >
            <Row type="flex" gutter={40}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10} className="campaingInfoCard" style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
                <div>
                  <h3>{t('Your campaign')}</h3>
                  { user && hasAccessRole(user.roles,[UserRoleEnum.agencyMember, UserRoleEnum.companyMember]) && this.props.client ? (
                    <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-12 clientBrandTag">
                      {t('Client')}: {this.props.client.name}
                    </div>
                  ) : null
                  }
                  { user && hasAccessRole(user.roles,[UserRoleEnum.agencyMember, UserRoleEnum.companyMember]) && this.props.brand ? (
                    <div className="clientBrandTag" style={{marginBottom: 30}}>
                      {t('Mark')}: {this.props.brand.name}
                    </div>
                  ) : null
                  }
                  <p className="titleField">
                    {t('Give your campaign a name so you can identify it')}
                  </p>
                  <Form.Item
                    validateStatus={!!errors["campaignName"] ? "error" : (inputCharLimit.validateStatus ? "warning" : null )}
                    help={errors["campaignName"] || inputCharLimit.errorMsg}
                  >
                    <Input
                      autoFocus
                      placeholder={t('Campaign name')}
                      value={this.props.campaignName}
                      onChange={this.handleNameChange.bind(this)}
                      maxLength={40}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <p className="titleField">
                    {t('Add more information if you need it')}
                  </p>
                  <Form.Item
                    validateStatus={areaCharLimit.validateStatus && "warning"}
                    help={errors["campaignDescription"] || areaCharLimit.errorMsg}
                  >
                    <TextArea
                     placeholder={t("Campaign extra information")}
                     onChange={this.handleDescriptionChange.bind(this)}
                     value={this.props.campaignDescription}
                     autoSize={{ minRows: 3, maxRows: 6 }}
                     maxLength={200}
                     className="textArea"
                     autoComplete="off"
                    />
                  </Form.Item>
                    <p className="titleField">{t("Categories")}</p>
                    <Select
                      defaultValue={null}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      className="selectCategories"
                      value={this.props.categoriesId}
                      onChange={this.changeCategory}
                      >
                      <Option key="placeholder" value={null} style={{ color: 'gray' }}>
                        {t("None")}
                      </Option>
                      {[...this.props.categories]  // Convert to a mutable structure
                        .sort((a, b) => {
                          const nameA = i18n.language === "en" ? a.name_en : a.name_es;
                          const nameB = i18n.language === "en" ? b.name_en : b.name_es;
                          return nameA.localeCompare(nameB);
                        })
                        .map(categorie => (
                          <Option key={categorie.id} value={categorie.id}>
                            {i18n.language === "en" ? categorie.name_en : categorie.name_es}
                          </Option>
                        ))}
                    </Select><br/>
                      { user && hasAccessRole(user.roles,
                        [ UserRoleEnum.superAdmin,
                          UserRoleEnum.superModeratorAgency,
                          UserRoleEnum.superModeratorMediaCompany,
                          UserRoleEnum.planner,
                          UserRoleEnum.companyMemberCommercial,
                          UserRoleEnum.companyMemberAdmin]) ? (
                        <span>
                          {t('Is it a test campaign?')}
                          &emsp;
                          <Switch
                              onChange={this.handleIsCampaignsTestToggle.bind(this)}
                              checked={this.props.isCampaignTest}
                              size="small"
                              disabled={(currentCampaign && !currentCampaign.smart_campaign) && this.props.editCampaignActive }
                            />
                          </span>
                      ) : ( null ) }
                      {this.props.editCampaignActive
                        && this.props.currentCampaign.payment_status !== 'pending'
                        && this.props.currentCampaign.payment_status !== 'cancelled'
                        && user
                        && (isSuperUser)
                        && (
                          <Form.Item style={{ marginBottom: 8 }}>
                            <span>
                              {t('Pause campaign')}
                              &emsp;
                              <Switch
                                onChange={this.handleIsCampaignsPausedToggle}
                                checked={this.getIsCampaignsPaused()}
                                size="small"
                              />
                            </span>
                          </Form.Item>
                        )}
                    {!this.props.editCampaignActive?
                      <>
                       {user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn) ? (
                              <div style={{marginTop: "10px"}}>
                                {t('Subsidize price of campaign')}
                                &emsp;
                                <Tooltip placement="bottomLeft" title={TooltipSubsidized}>
                                  <Switch
                                    checked={this.state.subsidized_all}
                                    onClick={() =>
                                      this.changeSubsidizedPrice()
                                    }
                                    size="small"
                                    disabled={!this.props.defaultContent.id}
                                  />
                                </Tooltip>
                                <Tooltip placement="bottomLeft" title={t('By enabling this option, the campaign is subsidize, your price will be zero')}>
                                  <Icon
                                  style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                  type="warning"
                                  />
                                </Tooltip>
                              </div>
                          ) : ( null ) }
                        </>:
                        <>
                          {this.state.subsidized_all?
                            <>
                              <br/>
                              <span className="ant-tag ant-tag-orange"
                                style={{marginTop: "5px"}}>
                                {t('Campaign subsidized')}
                              </span>
                            </>
                          :null}
                    </>}
                </div>
                <Form.Item
                  validateStatus={!!errors["defaultContent"] && "error"}
                  help={errors["defaultContent"]}
                >
                  {this.props.defaultContent.id || this.props.editCampaignActive ? (
                    <div className="draggerContainer">
                      <h3>{t('Reload')}</h3>
                      <p className="draggerContainerHelp">
                        {t('You have already uploaded your ad! If you want to change it for all screens, click below')}
                      </p>
                      <Upload {...uploadProps}>
                        <Button type="primary" className="button">
                          {t('Change spot material')}
                        </Button>
                      </Upload>
                      {this.props.programmatic === 'programmatic' ? (
                        <div>
                          <Button type="primary" style={{display: 'none'}} className="button addGeneralRuleButton" onClick={() => this.addGeneralRules()}>
                            {this.state.generalRuleActive ? (t('Remove')) : (t('Add'))} {t('general rule')}
                          </Button>
                          {this.state.generalRuleActive ? (
                          <Card title={t('General Rule (will apply to all screens)')} style={{borderRadius: 10}}>
                            <div style={{marginBottom: 15}}>
                              <RangePicker onChange={this.changeDate} format={['DD/MM/YYYY', 'DD/MM/YY']}/>
                            </div>
                            <div style={{marginBottom: 15}}>
                              <Checkbox onChange={this.changeDay} value="1">{t('Short day monday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="2">{t('Short day tuesday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="3">{t('Short day wednesday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="4">{t('Short day thursday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="5">{t('Short day friday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="6">{t('Short day saturday')}</Checkbox>
                              <Checkbox onChange={this.changeDay} value="0">{t('Short day sunday')}</Checkbox>
                            </div>
                            <div>
                              <TimePicker
                                onChange={this.changeStartTime}
                                format='HH:mm'
                                style={{marginRight: 15}}
                              />
                              <TimePicker
                                onChange={this.changeEndTime}
                                format='HH:mm'
                              />
                            </div>
                          </Card>
                          ) : null}
                        </div>
                      ) : null}
                      {!this.props.editCampaignActive ? (
                          <Button
                            className="button clearButton"
                            onClick={() => this.showClearCampaignDataModal()}
                          >
                            <Icon
                              style={{ fontSize: "18px"}}
                              type="delete"
                            />{" "}
                            {t('Delete ad')}
                          </Button>
                      ):null}
                      <br/>
                      <Modal
                        title={t('Clear campaign progress in progress')}
                        visible={visibleClearModal}
                        onOk={this.clearCampaignData}
                        onCancel={this.hideClearCampaignDataModal}
                        okText={t('Yes, clean')}
                        cancelText={t('Cancel')}
                      >
                        <p style={{ padding: 25 }}>{t('All the progress you have made so far in this campaign will be cleaned up, such as the campaign name, the ads for each screen, and the settings.')}</p>
                      </Modal>
                      <Button
                        className="button canvaButton"
                        onClick={() => this.showCanvaModal()}
                      >
                        <img
                          src={canvaLogo}
                          style={{height: "25px", marginRight: "6px"}}
                          alt=""
                        />{" "}
                        {t('Design and change spot')}
                      </Button>
                      <div style={{ marginBottom: "20px"}} >
                        <CartAudienceContainer view="cartAudienceVideoUpload" />
                      </div>
                      <Modal
                        visible={canvaModalVisible}
                        onCancel={this.handleCanvaClose}
                        footer={null}
                      >
                        <div className="modalCanva">
                          <h2>{t('What resolution will your main Spot have?')}</h2>
                        </div>
                        <div className="modalContent">
                          <Form.Item
                            validateStatus={!!errors["width"] ? "error" : ""}
                            help={errors["width"]}
                          >
                            <Input
                              autoFocus
                              name="width"
                              className="modalInput"
                              placeholder={t('Width (px)')}
                              value={this.state.width}
                              onChange={this.handleInputChange}
                              prefix={<Icon type="column-width" className="inputIcon" />}
                              autoComplete="off"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={!!errors["height"] ? "error" : ""}
                            help={errors["height"]}
                          >
                            <Input
                              autoFocus
                              name="height"
                              className="modalInput"
                              placeholder={t('Height (px)')}
                              value={this.state.height}
                              onChange={this.handleInputChange}
                              prefix={<Icon type="column-height" className="inputIcon" />}
                              onPressEnter={() => this.createCanvaDesign()}
                              autoComplete="off"
                            />
                          </Form.Item>
                          {t('You will need to create an account and log in the first time you want to create a design.')}
                          <Button
                            className="modalButton"
                            onClick={() => this.createCanvaDesign()}
                          >
                            {t('Start')}
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  ) : (
                    <div className="draggerContainer">
                      <h3>{t('Upload your ad')}</h3>
                      <p style={{ lineHeight: 1.2 }}>
                        {t('Upload your ad (an image or a video), to preview how it will look on each of the screens you chose.')}
                      </p>
                      <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" style = {{color: Colors.primary}}/>
                        </p>
                        <p>
                          {t('Drag the ad here or')} <br />
                          {t('click to load it.')} <br />
                          <span className="info-format-file" >(mp4 avi mpeg mpg mpv wmv m4v mov webm mkv m2t bmp jpeg jpg png svg webp tiff gif)</span>
                        </p>
                      </Dragger>
                      <br />
                      <h3>{t('Dont have the ad?')}</h3>
                      <p style={{ lineHeight: 1.2 }}>
                        {t('Contact us and we will advise you.')}
                      </p>
                      <Button
                        className="button canvaButton"
                        onClick={() => this.showCanvaModal()}
                      >
                        <img
                          src={canvaLogo}
                          style={{height: "25px", marginRight: "6px"}}
                          alt=""
                        />{" "}
                        {t('Design spot')}
                      </Button>
                      <div  style={{ marginBottom: "20px"}} >
                        <CartAudienceContainer view="cartAudienceVideoUpload" />
                      </div>
                      <Modal
                        visible={canvaModalVisible}
                        onCancel={this.handleCanvaClose}
                        footer={null}
                      >
                        <div className="modalCanva">
                          <h2>{t('What resolution will your main Spot have?')}</h2>
                        </div>
                        <div className="modalContent">
                          <Form.Item
                            validateStatus={!!errors["width"] ? "error" : ""}
                            help={errors["width"]}
                          >
                            <Input
                              autoFocus
                              name="width"
                              className="modalInput"
                              placeholder={t('Width (px)')}
                              defaultValue={this.state.width}
                              onChange={this.handleInputChange}
                              prefix={<Icon type="column-width" className="inputIcon" />}
                              type="number"
                              suffix="px"
                              autoComplete="off"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={!!errors["height"] ? "error" : ""}
                            help={errors["height"]}
                          >
                            <Input
                              name="height"
                              className="modalInput"
                              placeholder={t('Height (px)')}
                              defaultValue={this.state.height}
                              onChange={this.handleInputChange}
                              prefix={<Icon type="column-height" className="inputIcon" />}
                              onPressEnter={() => this.createCanvaDesign()}
                              type="number"
                              suffix="px"
                              autoComplete="off"
                            />
                          </Form.Item>
                          <Button
                            className="modalButton"
                            onClick={() => this.createCanvaDesign()}
                          >
                            {t('Start')}
                          </Button>
                        </div>
                      </Modal>
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=541136995144&text=Hola,%20me%20gustaría%20hacer%20una%20publicidad%20pero%20todavía%20no%20tengo%20la%20pieza%20gráfica.%20">
                        <Button type="primary" className="button">
                          {t('Contact us')}
                        </Button>
                      </a>
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14} className={this.props.defaultContent.id || this.props.editCampaignActive ? null : "videoUploadEmpty"} align={this.props.defaultContent.id || this.props.editCampaignActive ? null : "middle"}>
                {this.props.defaultContent.id || this.props.editCampaignActive ? (
                  <div>
                    {this.state.excessTraditionalVideoLength || this.state.excessProgrammaticVideoLength ? (
                      <Card className="warningCard">
                        {this.state.excessTraditionalVideoLength ? (
                          <span className="warningTextCard">
                            {t('Your video of')} {Math.floor(this.props.defaultContent.length / 1000)} {t('secs exceeds the playing time accepted by some selected screens. You can change the ad for a shorter one, or you can leave it and pay the proportional value for the time exceeded.')}
                          </span>
                        ) : (
                          <span className="warningTextCard">
                            {t('Your video of')} {Math.floor(this.props.defaultContent.length / 1000)} {t('secs exceeds the standard time for programmatic campaigns of 10 seconds. You can change the ad for a shorter time.')}
                          </span>
                        )}
                      </Card>
                    ) : (
                      <span>
                        {!this.props.editCampaignActive ? (
                          <Card className="successCard">
                            <span className="successTextCard">
                              {t('Your ad is')} {this.props.defaultContent.type === 'image' ? t('an image') : t('a video')}.{' '}
                              {this.props.defaultContent.type === 'image' ?
                              t('The time indicated by each screen will be displayed.')
                              : t('It has a duration of') + ' ' + (Math.round(this.props.defaultContent.length / 1000 * 100) / 100) + ' ' + t('secs, and this time will be displayed')}{'. '}
                              {t('You can change the ad for each of the selected screens. Keep in mind that if it is an image, the ad will display the space time indicated by each screen. For videos, it will be the time of the video.')}
                            </span>
                          </Card>
                        ) : (
                          <Card className="successCard" title={t('You are in campaign edit mode')}>
                            <span>
                              {t('Campaign')} {this.props.currentCampaign.id} - {this.props.currentCampaign.name}. {t('You can exit edit mode to create a new campaign.')} {" "}
                              <Button size="small" type="text" onClick={() => this.props.editCampaignInactive()}>
                                {t('Exit')}
                              </Button>
                            </span>
                          </Card>
                        )}
                      </span>
                    )}
                    {this.props.programmatic === 'programmatic' && this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ? (
                      <span>
                        <h3>
                          {t('Purchased impressions')}:{" "}
                          <span className="actuallyBudget">{this.state.totalPromisedShows}</span><span className="imprSubsidize">{this.state.subsidizeTotalPromisedShows} {t('subsidized')}</span>
                        </h3>
                        <h3>
                          {t('Printed so far')}:{" "}
                          <span className="actuallyBudget">
                            {this.state.totalShows}
                          </span>{" "}
                          <span style={{color: "#000000a6", fontSize: "12px"}}>({Math.round(this.state.totalShows * 100 / this.state.totalPromisedShows * 10) / 10}%)</span>
                        </h3>
                      </span>
                    ) : this.props.programmatic === 'programmatic' ? (
                      <h3>
                        {t('Updated budget')}:{" "}
                        <span className="actuallyBudget">
                          <NumberFormat value ={Math.round(this.props.realAmount * 100) / 100}
                                        currency= {this.props.editCampaignActive ? this.props.currentCampaign.currency: this.props.currency}
                          />
                        </span>
                        <Tooltip placement="right" title={t('Go back to the original budget and recalculate impressions (changes you have made in impressions for each Spot will be erased)')}>
                          <Button
                            shape="circle"
                            onClick={() => this.updateByAmountBudget()}
                          >
                            <Icon
                              style={{ fontSize: "17px"}}
                              type="undo"
                            />
                          </Button>
                        </Tooltip>
                      </h3>
                    ) : null }
                    {this.props.programmatic === 'programmatic' ? (
                      <h3>
                        {t('Selected screens')}:
                      </h3>
                    ) : <h3>{t('Selected screens')}</h3>}
                    <Spin size="large" spinning={this.props.loadingUploadFile} >
                      {displaysByAspectRatio.map(displays => (
                        <ScreenTypePreviewContainer
                          // arrContentChanged is by know what relation changed, then I use fot the loaders
                          arrContentChanged = {arrContentChanged}
                          key={displays.aspectRatio}
                          aspectRatio={displays.aspectRatio}
                          typeSize={displays.typeSize}
                          displays={displays.displays}
                          defaultContent={this.props.defaultContent}
                          campaignInvoiceIssuingCountry={this.props.editCampaignActive ?
                            this.props.currentCampaign.invoice_issuing.alpha_2_code :
                            this.props.invoiceIssuingCountry}
                          allDisplaysAreOwn={allDisplaysAreOwn}
                          permissionsTransferSpots= {this.state.permissionsTransferSpots}
                          creditAvailableOnTransferredImpressions={this.state.creditAvailableOnTransferredImpressions}
                          transferModalVisible={this.state.transferModalVisible}
                          showTransferModal={this.showTransferModal}
                          updateCreditAvailable = {this.updateCreditAvailable}
                        />
                      ))}
                    </Spin>
                  </div>
                ) : (
                  <span style={{color: "#a1a1a1", position: "sticky", top: "45%"}}>{t('Upload your main piece to see how it will look on each screen')}</span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal} message={t("Company is not verified and has more than $5000 USD in campaigns this month")}></VerifiedAgencyInfoModal>
      </VideoUploadHolder>
    );
  }
}

const Extended = withTranslation()(VideoUploadComponent);
Extended.static = VideoUploadComponent.static;

export default withRouter(
  Form.create({ name: "VideoUploadComponent" })(Extended)
);
VideoUploadComponent.contextTypes = {
  router: PropTypes.object
};

