import styled from "styled-components";

const ScreensCollectionHolder = styled.div`
  .Screens-collection {
    margin-top: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }

  .Screens-collection .collection{
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }

   .Screens-collection .title-collection {
    align-items: center;
    gap: 10px;
  }
  `;

export default ScreensCollectionHolder;