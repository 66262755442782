import React, { useEffect, useState} from 'react';
import { Icon, Row, Col, Collapse, Button, Tooltip, Switch, Modal, Divider} from "antd";
import { getSimilarAspectRatio } from "../services/display";
import ScreensGroupHolder from "./Styles/screensGroupStyle";
import { useTranslation } from 'react-i18next';
import Dotdotdot from "react-dotdotdot";
import Slider from "react-slick";
import RulesContainer from "../containers/RulesContainer";
import ListRulesComponent from "./ListRulesComponent";
import ScreenContainer from "../containers/ScreenContainer";
import RelationSliderComponent from "./RelationSliderComponent";
import AudienceImpactsComponent from "./AudienceImpactsComponent";
import { spotsPerDate } from '../services/contents';
import { calculateValueByCpm, hasAccessRole, calculeDaysOfCampaign} from '../services/utils';
import UserRoleEnum from "../constants/UserRoleEnum";
import { getCPM, getAmountAudience } from "../services/display";
import NumberFormat from "./NumberFormat";
import ModalEditCampaignManagerComponent from "./ModalEditCampaignManagerComponent";
import GenerateSkeletonComponent from './GenerateSkeletonComponent';

const { Panel } = Collapse;

const ScreensGroupComponent = (props) => {

  const {t}  = useTranslation();
  const {
    user,
    toggleFillScreen,
    customContents,
    audience,
    editCampaignActive,
    updateImpressionsDisplay,
    currentCampaign,
    removeDisplayOnCampaign,
    removeDisplayFromCart,
    managerDisplayFilter,
    disabledManagerDisplayFilter,
    disableManagerDisplayFilter,
    displaysCollection,
    reportSingleData,
    cart,
    date_from,
    date_to,
    programmatic,
    updateContentName,
    updateListOfContentsInCampaign,
    loadingSingleReport,
    currency,
    applyRelationOnScreens,
    allDisplaysAreOwn,
    updateSubsidizedPrice,
    removeRelationsOnCampaign,
    isPaymentStatusApproved
  } = props;

  // Modal width
  const modalWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth > 992 ? 900 : window.innerWidth;

  const [selectDisplayForRules, setSelectDisplayForRules] = useState(null);
  const [selectGroupDisplayRules, setSelectGroupDisplayRules] = useState(null);

  const [relationToDelete, setRelationToDelete] = useState(null);
  const [displaysGroups, setDisplaysGroups] = useState([]);

  const [showScreenDetail, setShowScreenDetail] = useState(false);
  const [displayModalInfo, setDisplayModalInfo] = useState(null);
  const [displayModalUpdateContent, setDisplayModalUpdateContent] = useState(null);
  const [indexGroup, setIndexGroup] = useState(null);

  const customPanelStyleContent = {
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
  }

  const groupByDimension = () => {
    const groups = [];

    displaysCollection.forEach(display => {
      const indice = getSimilarAspectRatio(display.resolution_width, display.resolution_height, t);
      const groupIndex = groups.findIndex(item => item.key === indice);
      display = {
        ...display,
        fill_screen: customContents.some(item => item.displayId === display.id && item.fill_screen === 1) ? 1 : 0
      };
      if (groupIndex === -1) {
        groups.push({ key: indice, displays: [display] });
      } else {
        groups[groupIndex].displays.push(display);
      }
    });
    return groups;
  };


  // Toggle fill screen for multiple relations for this display
  const groupToggleFillScreen = ({ key, displays, fill_screen }) => {
    let newFillScreen = fill_screen === 1 ? 0 : 1;  // change status of fill screen
    displays.forEach(screen => {
      toggleFillScreen(screen.id, screen.relationId, newFillScreen, 'multiple');
    });

    let newStateGroup = displaysGroups.map(group => {
      if (key === group.key) {
        return { ...group, fill_screen: newFillScreen };
      }
      return group; // Added return statement for unchanged groups
    });
    setDisplaysGroups([...newStateGroup]);
  }

  // Toggle fill screen for a single relation for this display
  const displayToggleFillScreen = (display) => {
    let newFillScreen = customContents.some(item => item.displayId === display.id && item.fill_screen === 1) ? 0 : 1;
    setDisplaysGroups(displaysGroups.map(group => {
      if (group.displays.find(item => item.id === display.id)) {
        // if not exist fill_screen in display, set 1 because is the first time
        let newFillScreen = display.fill_screen === undefined ? 1 : (display.fill_screen === 1 ? 0: 1);
        return { ...group, displays: group.displays.map(d => d.id === display.id ? { ...d, fill_screen: newFillScreen } : d) };
      }
      return group;
    }));
    toggleFillScreen(display.id, null, newFillScreen, 'multiple')
  }

  const getContentDisplayByScreenGroup = (displaysGroup) => {
    let listContentDisplay = []
    displaysGroup.map(screen => {
      let customContent =  customContents.filter(item => item.displayId === screen.id)
      listContentDisplay = [...listContentDisplay, ...customContent]
    })
    return listContentDisplay
  }

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleShowScreenDetail = (defaultTabScreenDetail, display=null) => {
    if (defaultTabScreenDetail === "close") {
      setDisplayModalInfo(null)
      setShowScreenDetail(!showScreenDetail);
      return
    }
    setDisplayModalInfo(display)
    setShowScreenDetail(!showScreenDetail);
  };

  const handleModalUpdateContent = (display) => {
    setDisplayModalUpdateContent(display);
    if (!display) {
      setDisplayModalUpdateContent(null)
      return
    }
    setDisplayModalUpdateContent(display)
  };

  const calculeValuesPerGroup = (groupDisplay) => {
    let investmentGroup = 0;
    let impactsGroup = 0
    groupDisplay.displays.forEach(display => {
      let filteredContents = customContents.filter(item => item.displayId === display.id);
      filteredContents.forEach(content => {
        investmentGroup += calculateValueByCpm(display.smart_campaign_cpm, content.promised_shows, "calculate_price");
        // investmentGroup += valueByCpm;
        impactsGroup += getAmountAudience(audience, content.relationId, 'impacts');
      });
    });
    return [investmentGroup, impactsGroup];
  }

  const durationCampaign = calculeDaysOfCampaign(date_from, date_to);

  const headerPanel = (groupDisplay, index) => {
    const {key, displays} = groupDisplay
    // search the custom contents for the displays of the group
    let contentsDisplay = customContents.filter(item => displays.some(display => display.id === item.displayId));
    let investmentGroup, impactsGroup
    [ investmentGroup, impactsGroup ] = calculeValuesPerGroup(groupDisplay);

    return (
      <Row style={{ alignItems: 'stretch' }}>
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
          <span>
            <div className='textGroupColor' style={{marginRight:"15px"}}>{key}</div>
            <div className="content-info">
              {displays.length} {displays.length === 0 ? t("display") : t("displays")}
            </div>
          </span>
            <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
          <span>
            <ListRulesComponent
              listContentDisplay={getContentDisplayByScreenGroup(displays)}
              styleRules="center"
              defaultText={t('There are no general rules for this group')}>
            </ListRulesComponent>
          </span>
          <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        <Col xs={5} sm={24} md={24} lg={24} xl={ programmatic === "programmatic" ? 4 : 12 } className="col-displays-header">
          <span>
            <Tooltip title={t('Add contents to screen group')}>
                <Button
                    className='buttonActionsGroup'
                    shape="circle"
                    onClick={(e) => {
                        stopPropagation(e);
                        setIndexGroup(index);
                    }}
                >
                    <Icon type="plus" />
                </Button>
            </Tooltip>
            <Tooltip title={ !groupDisplay.fill_screen  ? t('Expand ad') : t('Return original resolution')}>
              <Button
                className='buttonActionsGroup'
                shape="circle"
                onClick={(event) => {
                  stopPropagation(event);
                  groupToggleFillScreen(groupDisplay)
                }}
              >
                { !groupDisplay.fill_screen ? (<Icon type="fullscreen"/> ) : ( <Icon type="fullscreen-exit"/> )}
              </Button>
            </Tooltip>
            <Tooltip title={t('Set up rules in group of displays')}>
              <Button
                shape="circle"
                className='buttonActionsGroup'
                onClick={(event) => {
                    stopPropagation(event);
                    showModalGroupDisplayRules(key)
                }}>
                <Icon type="control" />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={displaysGroups.length < 2 ? t('To delete need at least two group in the campaign') : t('Remove group')}>
              <Button
                shape="circle"
                className='buttonActionsGroup'
                disabled = {displaysGroups.length < 2}
                onClick={() => showModalDeleteRelation('deleteGroupDisplays', displays)}>
                  {<Icon type="delete"/>}
              </Button>
            </Tooltip>
          </span>
          <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        { programmatic === "programmatic" && (
        <>
          <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
            <span style={{display: "flex",flexDirection: "column" }}>
              <div className='textGroup'> {t('Impressions')}</div>
              <div className='textGroup'>
                { !impactsGroup ?
                  <div style={{paddingLeft: 10}}>
                    <GenerateSkeletonComponent count={1} height={15} width={100} />
                  </div>:
                  <span className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                    { impactsGroup }
                  </span>
                }
              </div>
              <div className="content-info">
                {spotsPerDate(durationCampaign, contentsDisplay)} {t("spots / day")}
              </div>
            </span>
            <Divider type="vertical" style={{color: "black", height: "10em"}}/>
          </Col>
          <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
            <span style={{display: "flex",flexDirection: "column" }}>
              <div className='textGroup'> {t('Investment')}</div>
              <div className='textGroup'>
                <span className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                  <NumberFormat
                    tax_include={false}
                    value={investmentGroup}
                    currency={editCampaignActive ? currentCampaign.currency:currency}
                  />
                </span>
              </div>
              <div className="content-info">
                <Tooltip
                  placement="right"
                  title= {t('(CPM) cost per thousand person')}>
                    {getCPM(investmentGroup, impactsGroup)} {t("CPM")}
                </Tooltip>
              </div>
            </span>
            <Divider type="vertical" style={{color: "black", height: "10em"}}/>
          </Col>
        </>
        )}
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header" style={{marginTop: "15px"}}>
          <div>
            <div className='textGroup'> {t("Audience")}</div>
            <AudienceImpactsComponent displays={displays} audience={audience} />
          </div>
        </Col>
      </Row>
    )
  }

  // Remove and update impressions of campaign
  const removeRelationFromDisplayView = (relationToDelete) => {

    const {typeDelete, displayId, relationId} = relationToDelete;
    // Remove the display from the cart and the relation
    if (typeDelete === 'deleteDisplay') {
      removeDisplayFromCart(displayId)
      removeDisplayOnCampaign(displayId)
    }

    // Remove the display from the displaysCollection and the relation
    if (typeDelete === 'deleteGroupDisplays') {
      displayId.forEach(display => {
        removeDisplayFromCart(display.id)
        removeDisplayOnCampaign(display.displayId)
      })
    }

    if (typeDelete === 'deleteRelation') {
      // Remove the relation from the display
      let contentDisplayForDelete = customContents.find(item => item.relationId === relationId)
      let contentDisplayForUpdateImpression = customContents.filter(item => item.displayId === displayId)
      removeRelationsOnCampaign( displayId, contentDisplayForDelete.content.id )
      let countCustomesContent = 0
      countCustomesContent = customContents.filter(customContent => customContent.displayId === displayId).length
      countCustomesContent = countCustomesContent - 1

      let impressions = Math.trunc(contentDisplayForDelete.promised_shows / countCustomesContent)
      if (editCampaignActive && currentCampaign.payment_status === 'approved') {
        // updateImpressions(customContents, false);
      } else {
        contentDisplayForUpdateImpression.forEach(item => {
            let newPromisedShows = item.promised_shows + impressions;
            updateImpressionsDisplay(item.displayId, item.relationId, newPromisedShows);
        });
      }
    }

    showModalDeleteRelation();
    return;
  };

  // Variable to show the modal of the display when applying rules
  const showModalDisplayRules = (display = null) => {
      setSelectDisplayForRules(display ? display.id : null)
  }

  // Variable to show the modal of the group of displays when applying rules
  const showModalGroupDisplayRules = (indice = null) => {
    setSelectGroupDisplayRules(indice ? indice: null)
  }

  const showModalDeleteRelation = (typeDelete = null, displayId = null, relationId = null) => {
    if (!typeDelete){
      setRelationToDelete(null);
      return;
    }
    let description = '';
    if ( typeDelete === 'deleteRelation')
      description = t('Removing this spot will redistribute the impressions among the remaining spots.')

    if ( typeDelete === 'deleteDisplay')
      description = t('You are going to remove this screen from the campaign')

    if ( typeDelete === 'deleteGroupDisplays')
      description = t('You are going to remove this group of screens from the campaign')

    let deleteRelation = {
      typeDelete: typeDelete,
      displayId: displayId,
      relationId: relationId,
      description: description
    }
    setRelationToDelete(deleteRelation);
  };

  const getAspectRatio = (width, height) => {
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(width, height);
    return `${width / divisor}:${height / divisor}`;
  };

  const getApproximateAspectRatio = (width, height) => {
    const tolerance = 0.5;
    const aspect = width / height;
    const roundedAspect = Math.round(aspect / tolerance) * tolerance;
    return roundedAspect.toFixed(2);
  };

  const setGroupReduce = (acc, display, filter ) => {
    if (!acc || !display || !filter) return [];
    if (!acc[filter]) {
      acc[filter] = { key: filter, displays: [] };
    }
    acc[filter].displays.push(display);
    return acc;
  }

  const groupByAspectRatio = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const aspectRatio = getAspectRatio(display.resolution_width, display.resolution_height);
      const approximateAspectRatio = getApproximateAspectRatio(display.resolution_width, display.resolution_height);
      if (!acc[approximateAspectRatio]) {
        acc[approximateAspectRatio] = {
          key: aspectRatio,
          displays: []
        };
      }

      acc[approximateAspectRatio].displays.push(display);
      return acc;
    }, {}));
  };

  const groupByResolution = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const resolution = `${display.resolution_width}x${display.resolution_height}`;

      return setGroupReduce(acc, display, resolution);
    }, {}));
  };

  const groupByLocationType = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const locationType = display.location_type;

      return setGroupReduce(acc, display, locationType);
    }, {}));
  };

  const groupBySubType = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const name_type = display.open_ooh_venue_type_id ? `subtype_${display.open_ooh_venue_type_id}` : "Not assigned";
      const subtype = t(name_type);

      return setGroupReduce(acc, display, subtype);
    }, {}));
  };

  const updateSubsidizedPriceDisplay = (displayId, checked) => {
      updateSubsidizedPrice(displayId, checked, 'display')
  };

  const groupAccordingToFilter = () => {
    let oldDisplaysGroups = displaysGroups;
    let displaysGroupAux

    if (managerDisplayFilter === "aspect") displaysGroupAux = groupByAspectRatio();
    if (managerDisplayFilter === "dimensions") displaysGroupAux = groupByDimension();
    if (managerDisplayFilter === "resolution") displaysGroupAux = groupByResolution();
    if (managerDisplayFilter === "screen") displaysGroupAux = groupByLocationType();
    if (managerDisplayFilter === "subtype") displaysGroupAux = groupBySubType();

    // Defined the fill screen for the group of displays
    if (displaysGroupAux[0] && displaysGroupAux[0].fill_screen === undefined) {
      displaysGroupAux = displaysGroupAux.map(item => {
        let groupItem = oldDisplaysGroups.find(oldItem => oldItem.key === item.key);
        return {
          ...item,
          fill_screen: groupItem ? groupItem.fill_screen : 0
        };
      });
    }

    setDisplaysGroups(displaysGroupAux);
    disableManagerDisplayFilter(false);
  };

  useEffect(() => {
    groupAccordingToFilter();
  }, [cart, displaysCollection, customContents, managerDisplayFilter]);

  // useEffect(() => {
  //   displaysCollection.forEach(display => {
  //     if (!this.props.customContents.find(c => c.displayId === display.id)) {
  //       addRelationDisplay(
  //         display.id,
  //         display.id + '_' + (customContents.length + random(99999999)),
  //         Math.trunc((parseFloat(amountBudget) / this.props.cart.length ) / (display.smart_campaign_cpm/1000)))
  //       }}
  //   );
  // }, [displaysCollection]);

  return (
    <ScreensGroupHolder>
      <Row type="flex" className="screens-group">
        {!disabledManagerDisplayFilter && displaysGroups.map((group, index) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="appear-animation customPanelStyleDisplays" key={group.key}>
            <Collapse style={customPanelStyleContent}>
              <Panel
                header={headerPanel(group, index)}
                key={group.key}
              >
                <div className="horizontal-line"></div>
                {group.displays.map((display, indexDisplay) => (
                  <div style={{marginTop:"35px"}} key={display.id}>
                    <Row key={display.id} style={{display: "flex"}}>
                      <Col xs={24} sm={24} md={24} lg={4} xl={4} style={{paddingRight:"10px", display:"flex", justifyContent: "flex-start"}}>
                        <div>
                            <Dotdotdot clamp={1}>
                              <Tooltip title={display.name}>
                                <div className="textGroup" style={{marginBottom: "15px",textAlign: "center"}}>{display.name}</div>
                              </Tooltip>
                            </Dotdotdot>
                            <div className="textGroup" style={{marginBottom: "5px"}}>{t("Resolution")}</div>
                            <div className="textGroupColor">
                              {display.resolution_width}x{display.resolution_height}
                            </div>
                            <div style={{textAlign:"center", marginTop: "10px", marginBottom:"10px"}}>
                              <ListRulesComponent
                                listContentDisplay={ customContents.filter(item => item.displayId === display.id)}
                                styleRules="center"
                                defaultText={t('There are no general rules for this display')}>
                              </ListRulesComponent>
                            </div>

                            { selectDisplayForRules === display.id  &&
                              <Modal
                                key={display.id}
                                title={t("Create rules")}
                                visible={ selectDisplayForRules === display.id }
                                footer={[
                                  <Button key="ok" type="primary" onClick={() => showModalDisplayRules()}>
                                    OK
                                  </Button>,
                                ]}
                                onCancel={() => showModalDisplayRules()}
                                width={800}
                              >
                                <RulesContainer
                                  key={display.id}
                                  typeModal="display"
                                  listContentDisplay={ customContents.filter(item => item.displayId === display.id)}
                              />
                              </Modal>
                            }
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={16} xl={17} className="contentRelation" style={{paddingRight:"10px"}}>
                        <div>
                          <RelationSliderComponent
                            audience={audience}
                            display={display}
                            Slider={Slider}
                            customContents={customContents}
                            reportSingleData={reportSingleData}
                            currentCampaign={currentCampaign}
                            editCampaignActive={editCampaignActive}
                            showModalDeleteRelation={showModalDeleteRelation}
                            toggleFillScreen={toggleFillScreen}
                            date_to={date_to}
                            date_from={date_from}
                            programmatic={programmatic}
                            currency={currency}
                            updateImpressionsDisplay={updateImpressionsDisplay}
                            updateContentName={updateContentName}
                            updateListOfContentsInCampaign={updateListOfContentsInCampaign}
                            loadingSingleReport={loadingSingleReport}
                            user={user}
                            allDisplaysAreOwn={allDisplaysAreOwn}
                            updateSubsidizedPrice={updateSubsidizedPrice}
                            applyRelationOnScreens={applyRelationOnScreens}
                            isPaymentStatusApproved={isPaymentStatusApproved}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={4} xl={3} style={{paddingRight:"10px", display:"flex", justifyContent: "flex-end"}}>
                        <div style={{paddingLeft:"15px",display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <div style={{textAlign: "center"}}>
                            <div className='textGroup'> {t("Audience")}</div>
                            <AudienceImpactsComponent
                              displays={display}
                              audience={audience}
                              type='display'
                              user={user}
                              editCampaignActive={editCampaignActive}
                              />
                          </div>
                          {!editCampaignActive ?
                            <>
                              {(user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) ? (
                                  <div style={{ marginBottom: 30, marginTop: 10 }}>
                                      {t('Subsidize')}
                                      &emsp;
                                      <Switch
                                        checked = {customContents.filter(item => item.displayId === display.id).every(item => item.subsidized_price)}
                                        onClick={(checked) => updateSubsidizedPriceDisplay(display.id, checked)}
                                        size="small"
                                      />
                                    <Tooltip placement="bottomLeft" title={t('By enabling this option, the screen is subsidize, affecting the price of the campaign')}>
                                      <Icon
                                        style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                        type="warning"
                                      />
                                    </Tooltip>
                                  </div>
                              ) : ( null ) }
                            </>:<>
                                { customContents.filter(item => item.displayId === display.id).every(item => item.subsidized_price) ?
                                  ( display.promised_shows != 0 ?
                                    <span className="ant-tag ant-tag-orange tag-status-display">
                                      {t('Subsidized')}
                                    </span>
                                    : // When the user changes the value to zero.
                                    <span className="ant-tag ant-tag-blue tag-status-display">
                                        {t('Suspended')}
                                    </span>)
                                :null }
                            </>
                          }
                          <div style={{textAlign: "center", marginTop: "5px"}}>
                            <Tooltip title={t('Add contents on the screen')}>
                                <Button
                                    className='buttonActionsDisplayGroup'
                                    shape="circle"
                                    onClick={(e) => {
                                        handleModalUpdateContent(display);
                                    }}
                                >
                                    <Icon type="plus" />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={t("Screen detail")}>
                              <Button
                                shape="circle"
                                className='buttonActionsDisplayGroup'
                                onClick={() => handleShowScreenDetail("info", display)}
                              >
                                <Icon type="info-circle" theme="outlined" />
                              </Button>
                            </Tooltip>
                            <Tooltip title={t('Set up rules in this display')}>
                              <Button
                                shape="circle"
                                className='buttonActionsDisplayGroup'
                                onClick={() => showModalDisplayRules(display)}>
                                <Icon type="control" />
                              </Button>
                            </Tooltip>
                            { (((editCampaignActive && currentCampaign.status === 'ready') || !editCampaignActive) && !isPaymentStatusApproved ) &&
                              <Tooltip placement="top" title={displaysCollection.length < 2 ? t('To delete need at least two displays in the campaign') : t('Remove screen')}>
                                <Button
                                  shape="circle"
                                  className='buttonActionsDisplayGroup'
                                  disabled={group.displays.length < 2}
                                  onClick={() => showModalDeleteRelation('deleteDisplay', display.id)}
                                >
                                  <Icon type="delete" />
                                </Button>
                              </Tooltip>
                            }
                            <Tooltip title={ !display.fill_screen  ? t('Expand ad') : t('Return original resolution')}>
                              <Button
                                className='buttonActionsDisplayGroup'
                                shape="circle"
                                onClick={() => {
                                  displayToggleFillScreen(display)
                                }}
                              >
                                { !display.fill_screen ? (<Icon type="fullscreen"/> ) : ( <Icon type="fullscreen-exit"/> )}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Modal
                      title={t("Screen detail")}
                      visible={showScreenDetail && displayModalInfo.id === display.id}
                      onCancel={() => handleShowScreenDetail("close")}
                      footer={[<Button key="back" onClick={() => handleShowScreenDetail("close")}>OK</Button>]}
                      width={modalWidth}
                      style={{ top: "10px" }}
                    >
                      <ScreenContainer
                        display={displayModalInfo}
                        screenDetail={showScreenDetail}
                        popup={true}
                        defaultTab={"info"}
                        editCart={true}
                        closeModal={() => handleShowScreenDetail("close")}
                      />
                    </Modal>
                    {(displayModalUpdateContent && displayModalUpdateContent.id === display.id) &&
                      <ModalEditCampaignManagerComponent
                        visible={displayModalUpdateContent}
                        onCancel={() => handleModalUpdateContent(null)}
                        groupedDisplays={[displayModalUpdateContent]}
                        applyRelationOnScreens={applyRelationOnScreens}
                        isPaymentStatusApproved={isPaymentStatusApproved}
                      />
                    }
                  </div>
                ))}
              </Panel>
            </Collapse>
            { selectGroupDisplayRules === group.key &&
              <Modal
                title={t("Create rules")}
                visible={ selectGroupDisplayRules == group.key }
                onOk={() => showModalGroupDisplayRules()}
                onCancel={() => showModalGroupDisplayRules()}
                width={800}
              >
                <RulesContainer
                  key={group.key}
                  typeModal="groupDisplay"
                  listContentDisplay={ getContentDisplayByScreenGroup(group.displays) }
                />
              </Modal>
            }
            { indexGroup === index &&
              <ModalEditCampaignManagerComponent
                visible={indexGroup === index}
                onCancel={() => setIndexGroup(null)}
                groupedDisplays={group.displays}
                applyRelationOnScreens={applyRelationOnScreens}
                isPaymentStatusApproved={isPaymentStatusApproved}
              />
            }
          </Col>
        ))}
        {!!relationToDelete &&
          <Modal
            title={t('Are you sure you want to delete?')}
            visible={!!relationToDelete}
            onOk={() => removeRelationFromDisplayView(relationToDelete)}
            onCancel={() => showModalDeleteRelation()}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <p style={{margin: "20px"}}>{relationToDelete["description"]}</p>
          </Modal>
        }
      </Row>
    </ScreensGroupHolder>
  );
};

export default ScreensGroupComponent;