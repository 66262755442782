import React, { Component } from "react";
import CampaignCardHolder from "./Styles/campaignCardStyle";
import placeholder from "../images/bg.jpg";
import { Card, Icon, Button, Tooltip, Row, Col, Badge} from "antd";
import Colors from "../themes/Colors";
import { inherits } from "util";
import moment from "moment";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getUrl } from "../services/utils";
import Dotdotdot from "react-dotdotdot";
import { withRouter } from "react-router";
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole } from "../services/utils";
import OptionModal from "./OptionModal";
import CardDetailModal from "./CardDetailModal";
import { ProgrammaticSvg } from "../images/customIcons/ProgrammaticSvg";
import { TraditionalSvg } from "../images/customIcons/TraditionalSvg";
import { faInbox} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CategoryIcon from "./CategoryIconComponent";

class CampaignCard extends Component {
  state = {
    reportLoading: false,
    loadingDeleteCampaign: false,
    loadingArchiveCampaign: false,
    handleArchivedOk: false,
    proposalLoading: false,
    deleteModalVisible: false,
    detailModalVisible: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let lenguageParse
    switch (nextProps.i18n.language) {
      case "es":
        lenguageParse = "esLA";
        break;
      case "es_ar":
        lenguageParse = "es";
        break;
      default:
        break;
    }

    if (this.props.loadingReport && !nextProps.loadingReport && this.state.reportLoading) {
      const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.reportToken.token}`
      window.open(getUrl(window.location, "report.html", parameter));
      this.setState({ reportLoading: false });
    }
    if (this.props.loadingDeleteCampaign && !nextProps.loadingDeleteCampaign && this.state.loadingDeleteCampaign) {
      this.setState({ loadingDeleteCampaign: false });
    }
    if (this.props.loadingProposal && !nextProps.loadingProposal && this.state.proposalLoading) {
      const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.proposalToken.token}`
      window.open(getUrl(window.location, "proposal.html", parameter));
      this.setState({ proposalLoading: false });
    }
  }

  getProposal = () => {
    this.setState({ proposalLoading: true });
    this.props.getProposalRequest({
        campaign_id: this.props.campaign.id
    })
  }

  getReport = () => {
    this.setState({ reportLoading: true });
    var arrayDisplays = [];
    var arrayContents = [];
    this.props.campaign.contents_displays.map((display, index) => {
      arrayDisplays.push(display.display_id)
      arrayContents.push(display.content_id)
    });
    const { user } = this.props
    if ( user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember])) {
      this.props.getReportRequest({
        media_company_mode: false,
        properties: {
          company: this.props.campaign.company_id,
          campaigns: this.props.campaign.id
        }
      })
    } else {
      this.props.getReportRequest({
        properties: {
          displays: arrayDisplays.toString(),
          contents: arrayContents.toString(),
          from_date: moment(this.props.campaign.start_date).format('YYYY-MM-DD'),
          to_date: moment(this.props.campaign.end_date).format('YYYY-MM-DD'),
          display_data: 1
        }
      })
    }
  }

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  showArchivedModal = () => {
    this.setState({
      archiveModalVisible: true,
    });
  };

  showDetailModal = () => {
    this.setState({
      detailModalVisible: true,
    });
  };

  handleDeleteOk = () => {
    const { deleteCampaign, campaign } = this.props;
    this.setState({
      loadingDeleteCampaign: true,
      deleteModalVisible: false,
    });
    deleteCampaign(campaign.id);
  };

  handleArchivedOk = () => {
    const { archiveCampaign, campaign } = this.props;
    this.setState({
      loadingArchiveCampaign: true,
      archiveModalVisible: false,
    });
    let params = {
      archived: !campaign.archived
    }
    archiveCampaign(campaign.id, params);
  };

  handleDetailsOk = () => {
    this.setState({
      detailModalVisible: false,
    });

    this.props.history.push(`/campaign/${this.props.campaign.id}`)
  };

  handleDeleteModalCancel = () => {
    this.setState({
      deleteModalVisible: false,
    });
  };

  handleArchivedModalCancel = () => {
    this.setState({
      archiveModalVisible: false,
    });
  };

  handleDetailModalCancel = () => {
    this.setState({
      detailModalVisible: false,
    });
  };

  showProposalButton = () => {
    let { campaign, user, status } = this.props
    if (status === "previous") return false;
    if (!user) return false;
    if (campaign.payment_status !== 'pending' && campaign.payment_status !== 'approved') return false;
    if (!hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember])) return false;
    return true
  }

  render() {
    const { t, i18n, status, campaign} = this.props;

    const { deleteModalVisible, detailModalVisible , archiveModalVisible} = this.state;

    const rejectedCount = this.props.campaign.contents_displays.filter(
      d => d.revision_status === "rejected"
    ).length;

    const file_thumb = this.props.campaign.main_content_thumb
      ? this.props.campaign.main_content_thumb.replace(".mp4", "-00001.jpg")
      : placeholder;

    moment.locale(i18n.language);

    const ProgramaticIcon = (props) => <Icon component={ProgrammaticSvg} {...props} />;

    const TraditionalIcon = (props) => <Icon component={TraditionalSvg} {...props} />;

    return (
      <>
        <CampaignCardHolder>
          <OptionModal
            title={t('Atention!')}
            body={t('You are about to delete a campaign. Are you agree?')}
            visible={deleteModalVisible}
            okText={t('Ok')}
            cancelText={t('Cancel')}
            onOk={this.handleDeleteOk}
            onCancel={this.handleDeleteModalCancel}
          />
          <OptionModal
            title={t('Atention!')}
            body={status == "archived" ?
                  t('You are about unarchive a campaign'):
                  t('You are about archived a campaign, then you can unarchived in seccion archived')}
            visible={archiveModalVisible}
            okText={t('Ok')}
            cancelText={t('Cancel')}
            onOk={this.handleArchivedOk}
            onCancel={this.handleArchivedModalCancel}
          />
          <CardDetailModal
            title={t('Campaign details')}
            visible={detailModalVisible}
            okText={t('See campaign')}
            cancelText={t('Ok')}
            onOk={this.handleDetailsOk}
            onCancel={this.handleDetailModalCancel}
            data={this.props.campaign}
            t={t}
            i18n={i18n}
          />
          <Card
            className="campaignCard"
            style={{
              borderColor: rejectedCount > 0 ? Colors.red : inherits,
              borderWidth: rejectedCount > 0 ? "2px" : inherits
            }}
          >
          <Link
           to={`/campaign/${this.props.campaign.id}`}>
           {rejectedCount > 0 && (
            <div className="badge">
             {rejectedCount} {t('Ads rejected')}
             {rejectedCount === 1 ? "" : "s"}
            </div>)
            }

              <div className="campaignCardInfoNew">
                <Row align="middle">
                  <Col span={20}>
                    <Tooltip placement="top" title={(t('Campaign name') + ': ' + (this.props.campaign.name))}>
                      <span style={{ fontSize: "18px", fontWeight: "bold", color: "#909090" }}>
                        <div className="textResponsive" >
                          {campaign.name}
                        </div>
                      </span>
                    </Tooltip>
                    <span style={{ fontSize: "15px", color: "#909090", display: "flex", alignItems: "center" }}>
                      {campaign.client && campaign.brand &&
                        <Dotdotdot clamp={1}>
                          {`${campaign.client.name} ${campaign.brand.name}`}
                        </Dotdotdot>
                      }
                      {campaign.category &&
                        <>
                          <span style={{ margin: "0 5px" }}>-</span>
                          <span className="categoryTag">
                            <Tooltip
                              placement="top"
                              title={(t('Categories') + ': ' + (i18n.language === "en" ? `${campaign.category.name_en}` : `${campaign.category.name_es}`))}>
                              <span>
                                <CategoryIcon category={campaign.category} />
                              </span>
                            </Tooltip>
                          </span>
                        </>
                      }
                    </span>
                  </Col>
                  <Col span={4} style={{ left: "9%", paddingTop: "3%" }}>
                      {campaign.smart_campaign ? (
                      <Tooltip placement="top" title={t('Campaign created as Programmatic')}>
                        <ProgramaticIcon />
                      </Tooltip>
                      ) :
                      <Tooltip placement="top" title={t('Campaign created as Traditional')}>
                        <TraditionalIcon/>
                      </Tooltip> }
                  </Col>
                </Row>
              </div>
              <img src={file_thumb} className="campaignCardImage" alt="" />
          </Link>
            <div className="campaignCardFooter">
              <div style= {{ display:"flex", justifyContent :"space-between"}}>
                <span>
                  {campaign.contents_displays.length} Spot
                    {campaign.contents_displays.length === 1 ? " | " : "s | "}
                    {i18n.language === 'en' ? (
                      moment(campaign.start_date).format("MM/DD/YYYY")
                    ) : (
                      moment(campaign.start_date).format("DD/MM/YYYY")
                    )} - {i18n.language === 'en' ? (
                      moment(campaign.end_date).format("MM/DD/YYYY")
                    ) : (
                      moment(campaign.end_date).format("DD/MM/YYYY")
                    )
                    }{" "}
                    { status == "archived" ? (
                        <div className="tag-status">
                          {campaign.is_previous ? t("Previous"):t("Still to be paid")}
                        </div>
                      ) : null
                    }
                  </span>
                  { campaign.new_ssp ?
                    <span>
                      <Tooltip title={t("To modify this campaign, use the new version of the DSP with which it was created.")}>
                        <Badge
                          count={t("New version")}
                          style={{ backgroundColor: '#fa8c16' }}
                        />
                      </Tooltip>
                    </span>: null
                  }
                </div>
              <Row type="flex" justify="start">
                <Col span={4} style={{ display: "contents" }}>
                    <Tooltip
                      placement="bottom"
                      title={`
                      ${t('Campaign created by')} ${campaign.creator && campaign.creator.first_name} ${campaign.creator && campaign.creator.last_name}.
                      ${t('the day')} ${moment(campaign.created_at).format('LLLL')}.
                      ${t('Click for more information')}
                      `}>
                        <Button
                        className="cardButton"
                        onClick={this.showDetailModal}
                        >
                          {t('Detail')}
                        </Button>
                    </Tooltip>
                </Col>
                {this.showProposalButton() &&
                  <Col span={4} style={{ display: "contents" }}>
                    <Tooltip placement="bottom" title={t('Generate proposal')}>
                      <Button
                          className="cardButton"
                          loading={this.state.proposalLoading}
                          onClick={this.getProposal}
                      >
                        {t('Proposal')}
                      </Button>
                    </Tooltip>
                </Col>
                }
                {campaign.status === 'ready' ? (
                <Col span={4} style={{ display: "contents" }}>
                  <Tooltip
                    placement="bottom"
                    title={t('View report')}
                  >
                    <Button
                      className="cardButton"
                      loading={this.state.reportLoading}
                      onClick={this.getReport}
                    >
                      {t('Report')}
                    </Button>
                  </Tooltip>
                </Col>) : null
                }
                {campaign.status === 'pending' ? (
                  <Col span={4} style={{ display: "contents" }}>
                    <Tooltip placement="bottom" title={t('Delete campaign')}>
                      <Button
                      shape="circle"
                      className="cardButton"
                      loading={this.state.loadingDeleteCampaign}
                      onClick={this.showDeleteModal}>
                      {!this.state.loadingDeleteCampaign ? (
                        <Icon type="delete" theme="outlined"/>) : null
                      }
                      </Button>
                    </Tooltip>
                  </Col>) : null
                }
                {campaign.payment_status == "pending" || status == "archived" || (status == "previous" && campaign.payment_status == "pending" ) ? (
                  <Col span={4} style={{ display: "contents" }}>
                    <Tooltip placement="bottom" title={status == "archived" ? t('Unarchive campaign') : t('Archive campaign')}>
                      <Button
                        shape="circle"
                        className="cardButton"
                        loading={this.state.loadingArchiveCampaign}
                        onClick={this.showArchivedModal}>
                        {!this.state.loadingArchiveCampaign ? (
                          <FontAwesomeIcon icon={faInbox} size="20px"/>) : null
                        }
                      </Button>
                    </Tooltip>
                  </Col>) : null
                }
              </Row>
            </div>
          </Card>
        </CampaignCardHolder>
      </>
    );
  }
}

const Extended = withTranslation()(CampaignCard);
Extended.static = Extended.static;

export default withRouter(Extended);
