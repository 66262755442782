import React, { Component } from "react";
import InfoHolder from "./Styles/infoPartnersStyle";
import { Col, Card } from "antd";
import { withTranslation } from 'react-i18next';
import { default as SliderSlick } from "react-slick";

import partner1 from "../images/partners/partner-beeyond.png";
import partner2 from "../images/partners/partner-canva.png";
import partner3 from "../images/partners/partner-place-exchange.png";
import partner4 from "../images/partners/partner-scala.png";
import partner5 from "../images/partners/partner-taggify.png";
import partner6 from "../images/partners/partner-magnite.png";
import partner7 from "../images/partners/partner-dats-why.png";
import partner8 from "../images/partners/partner-ooh.png";
import partner9 from "../images/partners/partner-reach.png";
import partner10 from "../images/partners/partner-dv360.png";
import partner11 from "../images/partners/partner-thetradedesk.png";
import partner12 from "../images/partners/partner-mediamath.png";
import partner13 from "../images/partners/partner-taptap.png";
import partner14 from "../images/partners/partner-yahoo.png";

const { Meta } = Card;

class Info extends Component {
  state = {
    videoLatinadVisible: false,
    videoPublinetVisible: false
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { t , i18n } = this.props;
    var settings = {
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      className: "sliderCenter",
      centerMode: true,
      centerPadding: "60px",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    var partners = [
      partner1,
      partner3,
      partner9,
      partner10,
      partner11,
      partner12,
      partner4,
      partner5,
      partner6,
      partner7,
      partner8,
      partner2,
      partner13,
      partner14
    ]
    return (
      <InfoHolder>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          justify="center"
          align="middle"
          style={{ marginTop: "80px", marginBottom: "80px" }}
        >
          <h1>{t('Partners')}</h1>
          <h2>{t('Platform integrated to the DOOH and programmatic Ecosystem')}</h2>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="sliderContainer">
              <SliderSlick {...settings}>
                {partners.map((partner) => (
                  <div className="sliderCard">
                    <div className="sliderCardContent">
                      <img
                        className="imagePartner"
                        alt="displayers"
                        src={partner}
                      />
                    </div>
                  </div>
                ))}
              </SliderSlick>
            </div>
          </Col>
        </Col>
      </InfoHolder>
    );
  }
}
export default withTranslation()(Info);
