import React, { useEffect, useState } from 'react';
import { CircleFlag } from "react-circle-flags";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ScreensGroupContainer from "../containers/ScreensGroupContainer";
import ScreensCollectionHolder from "./Styles/screensCollectionStyle";

const { Title } = Typography;

const ScreensCollectionComponent = (props) => {
  const { t } = useTranslation();

  const [displaysCollections, setDisplaysCollections] = useState([]);

  const {
    cart,
    managerDisplaySelector,
    disabledManagerDisplayFilter,
    applyRelationOnScreens,
    allDisplaysAreOwn,
    isPaymentStatusApproved
  } = props;

  const getFlag = (collection) => {
    const firstDisplay = collection.displays[0];
    const countryAbbreviation = firstDisplay && firstDisplay.country_iso && firstDisplay.country_iso.toLowerCase();

    return countryAbbreviation;
  }

  const groupByCountry = () => {
    return Object.values(cart.reduce((acc, display) => {
      const country = display.country || "Not assigned";

      if (!acc[country]) {
        acc[country] = { key: country, displays: [] };
      }

      acc[country].displays.push(display);
      return acc;
    }, {}));
  };

  const groupAccordingToFilter = () => {
    let displaysGroupAux;

    if (managerDisplaySelector === "country") displaysGroupAux = groupByCountry();
    displaysGroupAux = groupByCountry();

    setDisplaysCollections(displaysGroupAux);
  };

  useEffect(() => {
    if (!managerDisplaySelector) return;

    groupAccordingToFilter();
  }, [cart, managerDisplaySelector]);

  return (
    <ScreensCollectionHolder>
      {managerDisplaySelector && !disabledManagerDisplayFilter ? (
        <Row type="flex" className="Screens-collection">
          {displaysCollections.map((collection, index) => (
            <Row
              key={index}
              className="collection"
              type="flex"
            >
              <Row type="flex" className="title-collection appear-animation-without-movement">
                <Title level={3}>{t(collection.key)}</Title>
                <CircleFlag countryCode={getFlag(collection)} height="24" width="24" />
                <Title level={3}>{`(${collection.displays.length || 0})`}</Title>
              </Row>
              <ScreensGroupContainer
                displaysCollection={collection.displays}
                applyRelationOnScreens={applyRelationOnScreens}
                allDisplaysAreOwn={allDisplaysAreOwn}
                isPaymentStatusApproved={isPaymentStatusApproved}
              />
            </Row>
          ))}
        </Row>
      ) : (
        <ScreensGroupContainer
          displaysCollection={cart}
          applyRelationOnScreens={applyRelationOnScreens}
          allDisplaysAreOwn = {allDisplaysAreOwn}
          isPaymentStatusApproved={isPaymentStatusApproved}
        />
      )}
    </ScreensCollectionHolder>
  );
};

export default ScreensCollectionComponent;