const TOLERANCES = [
  { "value": 0, "label": "0%", isSuper: false },
  { "value": 5, "label": "5%", isSuper: false },
  { "value": 10, "label": "10%", isSuper: false },
  { "value": 15, "label": "15%", isSuper: false },
  { "value": 20, "label": "20%", isSuper: false },
  { "value": 30, "label": "30%", isSuper: false },
  { "value": 50, "label": "50%", isSuper: true },
  { "value": 75, "label": "75%", isSuper: true },
  { "value": 90, "label": "90%", isSuper: true },
];

export default TOLERANCES;