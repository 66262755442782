import i18n from "i18next";
import moment from "moment";
import UrlEnum from "../constants/UrlEnum";

  export function getMinimunByCurrency(currency) {
    if (currency === 'USD') {
      return 100
    } else if (currency === 'ARS') {
      return 10000
    } else if (currency === 'COP') {
      return 100000
    } else if (currency === 'GTQ') {
      return 300
    } else if (currency === 'PEN') {
      return 150
    } else if (currency === 'CRC') {
      return 27000
    } else if (currency === 'HNL') {
      return 1000
    } else if (currency === 'NIO') {
      return 1500
    } else if (currency === 'SVC') {
      return 350
    } else if (currency === 'PAB') {
      return 40
    } else if (currency === 'CLF') {
      return 1
    } else if (currency === 'CLP') {
      return 20000
    } else if (currency === 'MXN') {
      return 700
    } else if (currency === 'DOP') {
      return 2000
    } else if (currency === 'UYU') {
      return 1500
    } else {
      return 100;
    }
  }

  export function SeparateNumberFormat(number){
    /*
     Checks if first two characters are "es"
     Possible matches:
      - es (latin american spanish was selected by user)
      - es_ar (argentine spanish was selected by user)
      - es-ES (when no language has been selected it might default to this value)
      - + any spanish variation that could be added in the future or set by the browser
    */
    const isSpanish = i18n.language.substring(0,2) === "es";
    const language = isSpanish ? "es-AR" : "en-US";
    return new Intl.NumberFormat(language).format(number)
  }

  // Calculate hour of impression depending on wednesday
  export function calculateHoursForImpressions(display){
    var wednesdayMinutes = null;
    // (24 * 60 )
    var completeDay = 1440
    if (display.working_hours && display.working_hours.length > 0) {
      var durationMinutes = 0
      for (var i = 0; i < display.working_hours.length; i++) {
        if (display.working_hours[i].day_of_week === 3) {
          wednesdayMinutes = wednesdayMinutes == null ? 0 : wednesdayMinutes
          durationMinutes = moment.utc(moment(display.working_hours[i].end_time, "HH:mm:ss").diff(moment(display.working_hours[i].start_time, "HH:mm:ss"), 'minutes'))._i;
          // This validation is when load for example 23:00 to 1:00
          if(durationMinutes < 0){
            durationMinutes = completeDay + durationMinutes;
          }
          wednesdayMinutes = wednesdayMinutes + durationMinutes;
        }
      }
    }
    return wednesdayMinutes == null ? completeDay : wednesdayMinutes
  }

  export const hasAccessRole = (userRoles, accessRoles) => {

    const userRolesNames = Object.values(userRoles);
    return userRolesNames.some(item => accessRoles.includes(item))
  };

  export function validateCharLimit(inputLenght, limit, message) {
    if (inputLenght === limit) {
      return {
        validateStatus: "error",
        errorMsg: message,
      };
    }
  }

  //Create url depending on location
  export function getUrl(location, pathname = '', parameters = '') {
    const baseUrls = {
      'latinad.com': 'http://gestor.latinad.com/',
      'app.mediashake.co': 'http://gestor.publinet.com.ar/',
      'market.grupovia.com': 'http://grupovia.latinad.com/',
      'publinet.com.ar': 'http://gestor.publinet.com.ar/',
      'publinet.us': 'http://cms.publinet.us/',
      'dooh.territoriodigital.co': 'http://territoriodigital.latinad.com/',
      'dooh.movimagen.uy': 'https://admin.dooh.movimagen.uy/',
      'adserver.smartfitmedia.com': 'http://gestor.latinad.com/',
      'dooh.duoprint.com.ec': 'http://duoprint.latinad.com/',
    };

    const baseUrl = baseUrls[location.hostname] || 'http://gestor.dev.publinet.com.ar/';
    const paramStr = parameters && pathname.includes('login') ? parameters : parameters ? '?' + parameters : '';

    return baseUrl + pathname + paramStr;
  }

  export function getDomain(domain) {
    const subDomain = domain.split('.').slice(1).join('.');
    const domainMappings = {
      "fenix.publinet.us": "latinad",
      [UrlEnum.grupoViaHostname]: "grupoVia",
      [UrlEnum.grupoViaSubdomain]: "grupoVia",
      [UrlEnum.mediaShakeHostname]: "mediaShake",
      [UrlEnum.mediaShakeSubdomain]: "mediaShake",
      [UrlEnum.smartFitHostname]: "smartFit",
      [UrlEnum.smartFitSubdomain]: "smartFit",
      [UrlEnum.duoPrintHostname]: "duoPrint",
      [UrlEnum.duoPrintSubdomain]: "duoPrint",
      [UrlEnum.territorioDigitalHostname]: "territorioDigital",
      [UrlEnum.territorioDigitalSubdomain]: "territorioDigital",
      [UrlEnum.movImagenHostname]: "movImagen",
      [UrlEnum.movImagenSubdomain]: "movImagen",
      [UrlEnum.yodaHostname]: "yoda",
      [UrlEnum.yodaSubdomain]: "yoda",
    };

    return domainMappings[domain] || domainMappings[subDomain] || "latinad";
  }

  export const calculateValueByCpm = (smart_campaign_cpm, promised_shows, action) => {
    var price = (Math.round((smart_campaign_cpm / 1000) * promised_shows * 100) / 100)
    if(action == "calculate_price"){
      return price
    }
    if(action == "difference_cpm_price"){
      return (smart_campaign_cpm - price)
    }
    if(action == "compare_cpm_impresion"){
     return Math.ceil(((smart_campaign_cpm - price) * 1000)/smart_campaign_cpm)
    }
  };

  export const calculateValueForPrice = (smart_campaign_cpm, promised_shows) => {
    var price = parseFloat(((smart_campaign_cpm / 1000) * promised_shows).toFixed(8));
    return price
  };

  export const metabaseUrl = "https://metabase.latinad.com/public/dashboard/";

  export const poiCategories = [
    {
      id: 1,
      slug: 'bar-and-coffee',
      name: "Bar & Coffee",
      icon: "coffee",
    },
    {
      id: 2,
      slug: 'beauty-care',
      name: "Beauty care",
      icon: "smile",
    },
    {
      id: 3,
      slug: 'education',
      name: "Education",
      icon: "read",
    },
    {
      id: 4,
      slug: 'entertainment',
      name: "Entertainment",
      icon: "play-circle",
    },
    {
      id: 5,
      slug: 'finance',
      name: "Finance",
      icon: "rise",
    },
    {
      id: 6,
      slug: 'health',
      name: "Health",
      icon: "heart",
    },
    {
      id: 7,
      slug: 'institutions',
      name: "Institutions",
      icon: "bank",
    },
    {
      id: 8,
      slug: 'restaurants',
      name: "Restaurants",
      icon: "shop",
    },
    {
      id: 9,
      slug: 'services',
      name: "Services",
      icon: "safety",
    },
    {
      id: 10,
      slug: 'shops',
      name: "Shops",
      icon: "shopping",
    },
    {
      id: 11,
      slug: 'transportation',
      name: "Transportation",
      icon: "branches",
    },
    {
      id: 12,
      slug: 'vehicles',
      name: "Vehicles",
      icon: "car",
    },
  ];
  // Is necessary because doctor not function with name without spaces
  export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  }

  // This function is for create an relation_id auxiliar when there is a smart list
  export const getRelationId = (relationId, indice = null) => {
    if(typeof relationId === 'string' && indice == null)
      relationId = parseInt(relationId.split("_")[0])
    if(indice != null)
      relationId = relationId + "_" + indice + "_SL"
    return relationId
  }

  export const formatNumber = (value, language) => {
      value = value ? value.toString().replace(/[a-zA-Z]/g, '') : ''; // Prevents entering letters
      // Let user keep writing the number until it is valid
      if (value && (value.toString().slice(-1) === '.' || value.toString().slice(-1) === ',')) {
          return value;
      }
      const number = isNaN(Number(value)) ? 0 : Number(value);
      const locale = language === 'es' ? 'es-ES' : 'en-US';
      const formatOptions = {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
      };
      return new Intl.NumberFormat(locale, formatOptions).format(number);
  }

  export const calculeDaysOfCampaign = (date_from, date_to) => {
    const dateFrom = moment(date_from);
    const dateTo = moment(date_to);
    return dateTo.diff(dateFrom, 'days') + 1;
  }

  export const arraysAreDifferent = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return true;
    return !arr1.every((element, index) => element === arr2[index]);
  }

  export const incrementVersion = (version) => {
    return version[0] + (parseInt(version.slice(1)) + 1);
}