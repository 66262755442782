import React from 'react';
import { Button, Col, Row, Select, Typography } from "antd";
import { useTranslation } from 'react-i18next';
import CampaignGroupFilterHolder from "./Styles/campaignGroupFilterStyle";
const { Text } = Typography;

const ScreensGroupFilterComponent = (props) => {
  const { t } = useTranslation();
  const {
    managerDisplayFilter,
    managerDisplaySelector,
    setManagerDisplaySelector,
    setManagerDisplayFilter,
    disabledManagerDisplayFilter,
    disableManagerDisplayFilter,
  } = props;

  const arrFilter = [
    {
      name: "aspect",
      label: "Aspect ratio",
    },
    {
      name: "dimensions",
      label: "General dimensions",
    },
    {
      name: "resolution",
      label: "Resolution",
    },
    {
      name: "screen",
      label: "Type of screen",
    },
    {
      name: "subtype",
      label: "Sub Type of screen",
    },
  ];

  const onClick = (filter) => {
    if (filter.name === managerDisplayFilter) return;

    setManagerDisplayFilter(filter.name);
    disableManagerDisplayFilter(true);
  }

  return (
    <CampaignGroupFilterHolder>
      <Row
        type="flex"
        align="top"
        justify="space-between"
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={19} className='groupFilter'>
          <div
            className="contentGroupFilter"
            type="flex"
            align="middle">
            <Text style={{marginRight: "5px"}} >{t("Group by")}:</Text>
            {arrFilter.map((filter) => (
              <Button
                key={filter.name}
                className='groupFilterButton'
                type={managerDisplayFilter === filter.name ? "primary" : ""}
                disabled={disabledManagerDisplayFilter}
                onClick={() => onClick(filter)}
              >
                {t(filter.label)}
              </Button>
            ))}
          </div>
        </Col>
        <Col>
          <div
            className="contentGroupFilter"
            type="flex"
            align="middle"
          >
            <Text>{t("Distributed by")}:</Text>
            <Select
              value={managerDisplaySelector}
              onChange={setManagerDisplaySelector}
              style={{ width: 130, marginLeft: "10px" }}
            >
              <Select.Option key={"nothing"} value={""}>
                {t("None")}
              </Select.Option>
              <Select.Option key={"country"} value={"country"}>
                {t("Countries")}
              </Select.Option>
            </Select>
          </div>
        </Col>
      </Row>
    </CampaignGroupFilterHolder>

  );
};

export default ScreensGroupFilterComponent;
